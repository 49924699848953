// import { useTheme } from '@emotion/react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';

import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/routes/paths';

import { useBoolean } from 'src/hooks/use-boolean';
// import { useGetRoles } from 'src/hooks/useHandleSessions';

import { Tab, Tabs } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import { getAllStudentExam } from 'src/server/api/studentExam';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSnackbar } from 'src/components/snackbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  // emptyRows,
  TableNoData,
  // TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';

import StudentExamTableRow from 'src/sections/studentExam/student-exam-table-row';
import StudentExamTableToolbar from 'src/sections/studentExam/student-exam-table-toolbar';
import StudentExamFiltersResult from 'src/sections/studentExam/student-exam-filters-result';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function StudentExamList({ studentId }) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  // const theme = useTheme();
  const settings = useSettingsContext();
  const router = useRouter();
  const theme = useTheme();
  console.log(studentId);
  const params = useParams();
  const parmsId = params.id;
  const table = useTable({ defaultOrderBy: 'createdAt' });
  const confirm = useBoolean();
  const [studentExamStatus, setStudentExamStatus] = useState('');
  const [studentExamStatusLabel, setStudentExamStatusLabel] = useState();

  const [selectedStudent, setSelectedStudent] = useState('');
  const [selectedCourse, setSelectedCourse] = useState('');
  // const { role } = useGetRoles();
  const { loading, studentExams } = useSelector((state) => ({
    loading: state.studentExam.loading,
    studentExams: state.studentExam.studentExams,
  }));

  console.log('exam', studentExams);

  // const [saleStatus, setSaleStatus] = useState('');
  // const [selectedServices, setSelectedServices] = useState([]);

  // console.log(saleStatus);
  // const handleServiceChange = (selected) => {
  //   setSelectedServices(selected);
  // };

  // Pass the selected values to the SecondModel as a prop
  // const propsForSecondModel = {
  //   saleStatus,
  //   selectedServices,
  // };

  const [searchText, setSearchText] = useState();
  const [sortDate, setSortDate] = useState({
    from: '',
    to: '',
  });
  const [sort, setSort] = useState('');

  const [tableData, setTableData] = useState([]);
  console.log('tableData', tableData);
  const totalPage = studentExams?.total;

  const TABLE_HEAD = [
    { id: '', label: 'No.' },
    { id: 'createDate', label: 'Entrolled at' },
    { id: 'Exam', label: 'Exam' },
    { id: 'exam_grade', label: 'Exam Type' },
    { id: 'course', label: 'Course' },
    { id: 'completion_percentage', label: 'Completion %' },
    { id: 'pass_percentage', label: 'Pass %' },
    { id: 'status', label: 'Status' },
    { id: 'exam_grade', label: 'Grade' },
    { id: '', label: '' },
  ];
  const studentLabel = { id: 'student', label: 'Student' };
  if (!studentId) {
    TABLE_HEAD.splice(2, 0, studentLabel);
  }
  const identifier = 'admin-StudentExams';
  const storedRowsPerPage = sessionStorage.getItem(`rowsPerPage_${identifier}`);
  useEffect(() => {
    const credentials = {
      page: table.page + 1,
      limit: storedRowsPerPage || table.rowsPerPage || 5,
      date: sortDate,
      sort,
      student: parmsId || studentId || selectedStudent?.value,
      status: studentExamStatus,
      course: selectedCourse?.value,
      dispatch,
    };
    dispatch(getAllStudentExam(credentials));
    console.log('credentials =>', credentials);
  }, [
    dispatch,
    searchText,
    table.page,
    table.rowsPerPage,
    storedRowsPerPage,
    studentId,
    studentExamStatus,
    sortDate,
    sort,
    selectedCourse,
    parmsId,
    selectedStudent?.value,
  ]);

  useEffect(() => {
    setTableData(studentExams?.studentExams);
  }, [studentExams]);

  const dataInPage = [];

  const canReset = false;

  const notFound = (!tableData?.length && canReset) || !tableData?.length;

  const TABS = [
    {
      value: '',
      label: 'All',
      color: 'default',
      count: studentExams?.count?.allCount ?? 0,
    },
    {
      value: 'completed',
      label: 'Completed',
      color: 'info',
      count: studentExams?.count?.completedCount ?? 0,
    },
    {
      value: 'certificate-issued',
      label: 'Certificate Issued',
      color: 'success',
      count: studentExams?.count?.certificateIssuedCount ?? 0,
    },
  ];

  const handleDeleteRow = useCallback(
    (id) => {
      const deleteRow = tableData?.filter((row) => row.id !== id);
      enqueueSnackbar('Delete success!');
      setTableData(deleteRow);
      table.onUpdatePageDeleteRow(dataInPage?.length);
    },
    [dataInPage?.length, enqueueSnackbar, table, tableData]
  );

  const handleDeleteRows = () => {};

  const handleEditRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id) => {
      router.push(paths.dashboard.invoice.details(id));
    },
    [router]
  );
  // const handleEditClick = (row) => {
  //   if (row) {
  //     setOpenExamId(row);
  //   }
  //   setOpenCreateExamDialog(true);
  // };
  const handleFilters = (Type) => {
    switch (Type) {
      case 'Status':
        break;

      case 'Student':
        setSelectedStudent();
        break;

      case 'Student Exam Status':
        setStudentExamStatusLabel();
        break;
      case 'Search':
        setSearchText();
        break;
      case 'Reset':
        setSelectedStudent();
        setSelectedCourse();
        setStudentExamStatusLabel();
        setSearchText();
        break;

      default:
        break;
    }
  };
  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading="Student Exams"
          links={[
            {
              name: 'Dashboard',
              href: paths.dashboard.root,
            },

            {
              name: 'Student Exams',
            },
          ]}
          // action={
          //   <Button
          //     onClick={() => setOpenCreateExamDialog(true)}
          //     variant="contained"
          //     startIcon={<Iconify icon="mingcute:add-line" />}
          //   >
          //     New Exam
          //   </Button>
          // }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Tabs
            value={sort}
            onChange={(e, newValue) => setSort(newValue)}
            sx={{
              px: 2.5,
              boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {TABS?.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                iconPosition="end"
                icon={<Label color={tab.color}>{tab.count}</Label>}
              />
            ))}
          </Tabs>
          <StudentExamTableToolbar
            studentExamStatus={studentExamStatus}
            setStudentExamStatus={setStudentExamStatus}
            setStudentExamStatusLabel={setStudentExamStatusLabel}
            // filters={sort}
            dateFilter={{ sate: sortDate, setSate: setSortDate }}
            onFilters={(e, searchTextValue) => setSearchText(searchTextValue)}
            setSelectedStudent={setSelectedStudent}
            setSelectedCourse={setSelectedCourse}
            studentId={studentId}
            // isSearchAvailable
          />
          {!table?.dense &&
            (selectedStudent?.label ||
              selectedCourse?.label ||
              studentExamStatusLabel ||
              searchText) && (
              <StudentExamFiltersResult
                filters={[
                  {
                    title: 'Student',
                    value: selectedStudent?.label,
                  },
                  {
                    title: 'Exam Status',
                    value: studentExamStatusLabel,
                  },

                  {
                    title: 'Search',
                    value: searchText,
                  },
                ]}
                onFilters={handleFilters}
                onResetFilters={handleFilters}
                results={tableData?.length}
                sx={{ p: 2.5, pt: 0 }}
              />
            )}
          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={table.dense}
              numSelected={table.selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) => {
                table.onSelectAllRows(
                  checked,
                  tableData.map((row) => row.id)
                );
              }}
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="iconamoon:send-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="solar:printer-minimalistic-bold" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={table.order}
                  orderBy={table.orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={table.selected?.length}
                  onSort={table.onSort}
                  // onSelectAllRows={(checked) =>
                  //   table.onSelectAllRows(
                  //     checked,
                  //     tableData.map((row) => row.id)
                  //   )
                  // }
                />

                <TableBody>
                  {(tableData || []).map((row, index) => (
                    <StudentExamTableRow
                      // setOpenCreateExamDialog={setOpenCreateExamDialog}
                      // setOpenExamId={setOpenExamId}
                      index={index}
                      page={table.page + 1}
                      rowsPerPage={storedRowsPerPage || table.rowsPerPage || 5}
                      // handleEditClick={handleEditClick}
                      key={row.id}
                      row={row}
                      selected={table?.selected?.includes(row.id)}
                      onSelectRow={() => table.onSelectRow(row.id)}
                      onViewRow={() => handleViewRow(row.id)}
                      onEditRow={() => handleEditRow(row.id)}
                      onDeleteRow={() => handleDeleteRow(row.id)}
                      loading={loading}
                      colSpan={TABLE_HEAD?.length}
                      studentId={studentId}
                    />
                  ))}
                </TableBody>
                {/* <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(table.page, table.rowsPerPage, tableData?.length)}
                />  */}

                <TableNoData notFound={notFound} />
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalPage || 0}
            page={table.page}
            rowsPerPage={table.rowsPerPage}
            onPageChange={table.onChangePage}
            onRowsPerPageChange={table.onChangeRowsPerPage}
            dense={table.dense}
            onChangeDense={table.onChangeDense}
            identifier={identifier}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title="Delete"
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteRows();
              confirm.onFalse();
            }}
          >
            Delete
          </Button>
        }
      />
    </>
  );
}

StudentExamList.propTypes = {
  studentId: PropTypes.any,
};
