import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

import { bgGradient } from 'src/theme/css';

import Iconify from 'src/components/iconify';
// import Chart, { useChart } from 'src/components/chart';

// ----------------------------------------------------------------------

export default function BankingWidgetSummary({
  title,
  total,
  icon,
  percent,
  color = 'primary',
  chart,
  sx,
  ...other
}) {
  const theme = useTheme();

  // const { series, options } = chart;

  // const chartOptions = useChart({
  //   colors: [theme.palette[color].dark],
  //   chart: {
  //     sparkline: {
  //       enabled: true,
  //     },
  //   },
  //   xaxis: {
  //     labels: {
  //       show: false,
  //     },
  //   },
  //   yaxis: {
  //     labels: {
  //       show: false,
  //     },
  //   },
  //   legend: {
  //     show: false,
  //   },
  //   grid: {
  //     show: false,
  //   },
  //   tooltip: {
  //     marker: {
  //       show: false,
  //     },
  //     y: {
  //       formatter: (value) => fCurrency(value),
  //       title: {
  //         formatter: () => '',
  //       },
  //     },
  //   },
  //   ...options,
  // });

  return (
    <Stack
      sx={{
        ...bgGradient({
          direction: '135deg',
          startColor: alpha(theme.palette[color].light, 0.2),
          endColor: alpha(theme.palette[color].main, 0.2),
        }),
        width: 1,
        borderRadius: 2,
        overflow: 'hidden',
        position: 'relative',
        color: `${color}.darker`,
        backgroundColor: 'common.white',
        ...sx,
      }}
      {...other}
    >
      <Iconify
        icon={icon}
        sx={{
          p: 1.5,
          top: 24,
          right: 24,
          width: 48,
          height: 48,
          borderRadius: '50%',
          position: 'absolute',
          color: `${color}.lighter`,
          bgcolor: `${color}.dark`,
        }}
      />
      <Stack spacing={1} sx={{ p: 0 }}>
        <Typography variant="h4" textTransform="capitalize" textAlign="center" pt={4.5}>
          {other?.type}
        </Typography>
      </Stack>
      <Stack sx={{ p: 3 }} direction="row" justifyContent="space-between">
        <Typography variant="subtitle2">
          {title[0]}
          <Typography variant="h3" textAlign="center">
            {other?.directIncome}
          </Typography>
        </Typography>

        <Typography variant="subtitle2">
          {title[1]}
          <Typography variant="h3" textAlign="center">
            {other?.hierarchyIncome}
          </Typography>
        </Typography>

        {/* <Stack
          spacing={0.5}
          direction="row"
          flexWrap="wrap"
          alignItems="center"
          sx={{ typography: 'body2' }}
        >
          <Iconify icon={percent < 0 ? 'eva:trending-down-fill' : 'eva:trending-up-fill'} />

          <Box sx={{ typography: 'subtitle2' }}>
            {percent > 0 && '+'}
            {fPercent(percent)}
          </Box>

          <Box sx={{ opacity: 0.8 }}>than last month</Box>
        </Stack> */}
      </Stack>

      {/* <Chart
        dir="ltr"
        type="area"
        series={[{ data: series }]}
        options={chartOptions}
        width="100%"
        height={120}
      /> */}
    </Stack>
  );
}

BankingWidgetSummary.propTypes = {
  chart: PropTypes.object,
  color: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  percent: PropTypes.number,
  sx: PropTypes.object,
  title: PropTypes.any,
  total: PropTypes.number,
};
