import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createSubscriptionList,
  deleteSubscriptionList,
  getAllSubscriptionList,
  updateSubscriptionListDetails,
} from '../api/subscription';

export const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState: {
    loading: false,
    error: {},
    // staff
    subscriptions: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get all subscription
    [getAllSubscriptionList.pending]: (state) => {
      state.loading = true;
    },
    [getAllSubscriptionList.fulfilled]: (state, action) => {
      state.loading = false;
      state.subscriptions = action.payload;
      state.error = {};
    },
    [getAllSubscriptionList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [createSubscriptionList.pending]: (state) => {
      state.loading = true;
    },
    [createSubscriptionList.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subscriptions;
      const newSubscriptionsList = jsonState?.subscriptions;

      const modifiedSubscriptionList = {
        ...jsonState,
        subscriptions: Array.isArray(newSubscriptionsList)
          ? [action.payload, ...newSubscriptionsList]
          : [action.payload],
      };
      state.loading = false;
      // state.SubscriptionDetails = action.payload;
      state.subscriptions = modifiedSubscriptionList;
      state.error = {};
    },
    [createSubscriptionList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateSubscriptionListDetails.pending]: (state) => {
      state.loading = true;
    },
    // [updateSubscriptionDetails.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   state.SubscriptionDetails = action.payload;
    //   state.error = {};
    // },
    [updateSubscriptionListDetails.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subscriptions;
      const modifiedSubscriptionList = {
        ...jsonState,
        subscriptions: jsonState.subscriptions?.map((i) =>
          i._id === action.payload._id ? action?.payload : i
        ),
      };

      state.loading = false;
      // state.subscription = modifiedSubscriptionList;
      state.SubscriptionDetails = action.payload;
      state.subscriptions = modifiedSubscriptionList;
      state.error = {};
    },
    [updateSubscriptionListDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deleteSubscriptionList.pending]: (state) => {
      state.loading = true;
    },
    [deleteSubscriptionList.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.subscriptions;
      const modifiedSubscriptionList = {
        ...jsonState,
        subscriptions: jsonState.subscriptions?.filter(
          (subscription) => subscription._id !== action.payload
        ),
      };
      state.loading = false;
      state.subscriptions = modifiedSubscriptionList;
      state.error = {};
    },
    [deleteSubscriptionList.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});
export const { clearStaffError } = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
