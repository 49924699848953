import PropTypes from 'prop-types';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// @mui
import {
  Box,
  Stack,
  Dialog,
  MenuItem,
  Checkbox,
  TextField,
  Typography,
  DialogTitle,
  Autocomplete,
  DialogContent,
  DialogActions,
  FormControlLabel,
} from '@mui/material';

import { getAllCustomFields } from 'src/server/api/customField';

FormFieldCrud.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  formFieldData: PropTypes.any,
  setFormFieldData: PropTypes.func,
  formFieldItem: PropTypes.any,
  setFormFieldItem: PropTypes.any,
};

export default function FormFieldCrud({
  open,
  setOpen,
  formFieldData,
  setFormFieldData,
  formFieldItem,
  setFormFieldItem,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();

  const { customFields } = useSelector((state) => ({
    customFields: state.customField.customFields,
  }));

  const handleClose = () => {
    setOpen(false);
    setFormFieldItem({});
  };

  const defaultCustomField = useMemo(
    () => ({
      label: formFieldItem?.custom_field?.title || '',
      value: formFieldItem?.custom_field?._id || '',
    }),
    [formFieldItem]
  );

  const [selectedCategory, setSelectedCategory] = useState('Default');
  const [selectedDefaultField, setSelectedDefaultField] = useState('');
  const [isRequired, setIsRequired] = useState(false);

  const [customField, setCustomField] = useState(defaultCustomField || {});
  const [searchCustomField, setSearchCustomField] = useState('');

  const handleAddData = () => {
    if (formFieldItem?._id) {
      const updatedData = formFieldData?.map((item) =>
        item?._id === formFieldItem._id
          ? {
              ...item, // Keep other properties of the item
              is_default_field: selectedCategory === 'Default',
              custom_field: selectedCategory === 'Custom' ? customField : null,
              default_field: selectedDefaultField || null,
              is_required: isRequired,
            }
          : item
      );
      setFormFieldData(updatedData);
    } else {
      setFormFieldData((prevItems) => [
        ...prevItems,
        {
          _id: prevItems.length + 1,
          is_default_field: selectedCategory === 'Default',
          custom_field: selectedCategory === 'Custom' ? customField : null,
          default_field: selectedDefaultField || null,
          is_required: isRequired,
        },
      ]);
    }
    setSelectedCategory('Default');
    setSelectedDefaultField('');
    setIsRequired(false);
    handleClose();
  };

  useEffect(() => {
    if (formFieldItem?._id) {
      setSelectedCategory(formFieldItem?.is_default_field ? 'Default' : 'Custom');
      setSelectedDefaultField(formFieldItem?.default_field);
      setIsRequired(formFieldItem?.is_required);
    }
  }, [formFieldItem]);

  const types = [
    {
      label: 'Name',
      value: 'NAME',
    },
    {
      label: 'Phone',
      value: 'PHONE',
    },
    {
      label: 'Email',
      value: 'EMAIL',
    },
    {
      label: 'Grade',
      value: 'GRADE',
    },
    {
      label: 'Country',
      value: 'COUNTRY',
    },
    {
      label: 'State',
      value: 'STATE',
    },
    {
      label: 'Disrict',
      value: 'DISTRICT',
    },
    {
      label: 'Other Country',
      value: 'OTHER_COUNTRY',
    },
    {
      label: 'Other State',
      value: 'OTHER_STATE',
    },
    {
      label: 'Other District',
      value: 'OTHER_DISTRICT',
    },
  ];

  // const uniqueToTypes = types?.filter(
  //   (type) => !formFieldData?.some((itm) =>  type.value === itm?.default_field)
  // );

  // const uniqueToTypes2 = formFieldData.filter(
  //   (itm) => !types?.some((type) => type.value === itm.default_field)
  // );

  // const nonCommonTypes = [...uniqueToTypes, ...uniqueToTypes2];

  const handleFieldChange = (event) => {
    setSelectedDefaultField(event.target.value);
  };

  const optionCustomField = [
    ...(customFields?.customFields || []).map((results) => ({
      label: results?.title,
      value: results?._id,
    })),
  ];

  const handleAutocompleteChangeCustomField = (_, newValue) => {
    setCustomField({
      value: newValue?.value || '',
      label: newValue?.label || '',
    });
  };

  const handleTextFieldChangeCustomField = (event) => {
    setSearchCustomField(event.target.value);
  };

  useEffect(() => {
    const credential = {
      page: 1,
      limit: 10,
      search: searchCustomField,
      dispatch,
    };
    dispatch(getAllCustomFields(credential));
  }, [dispatch, searchCustomField]);

  const renderForm = (
    <>
      <Typography variant="subtitle2" pb={1}>
        Type
      </Typography>
      <Stack direction="row">
        <Button
          onClick={() => setSelectedCategory('Default')}
          sx={{
            backgroundColor: selectedCategory === 'Default' ? '#007867' : 'white',
            color: selectedCategory === 'Default' ? 'white' : 'black',
            borderRadius: '8px 0 0 8px',
            border: selectedCategory === 'Default' ? 'none' : '1px solid gray',
            width: '120px',
            '&:hover': {
              backgroundColor: '#005d50',
              color: 'white',
            },
          }}
        >
          Default
        </Button>
        <Button
          onClick={() => setSelectedCategory('Custom')}
          sx={{
            backgroundColor: selectedCategory === 'Custom' ? '#007867' : 'white',
            color: selectedCategory === 'Custom' ? 'white' : 'black',
            borderRadius: '0 8px 8px 0',
            border: selectedCategory === 'Custom' ? 'none' : '1px solid gray',
            width: '120px',
            '&:hover': {
              backgroundColor: '#005d50',
              color: 'white',
            },
          }}
        >
          Custom
        </Button>
      </Stack>
      <Stack my={3}>
        {selectedCategory === 'Default' ? (
          <TextField
            id="outlined-select-verified"
            select
            label="Default Fields"
            size="medium"
            name="default_field"
            value={selectedDefaultField}
            defaultValue={selectedDefaultField || ''}
            onChange={handleFieldChange}
          >
            {types?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <Autocomplete
            id="partner-select-demo"
            sx={{ width: '100%' }}
            options={optionCustomField}
            autoHighlight
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                {option?.label}
              </Box>
            )}
            value={customField}
            // defaultValue={defaultBrand}
            // defaultValue={selectedRental}
            onChange={handleAutocompleteChangeCustomField}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Custom Field"
                // name="head_partner_type"
                onChange={handleTextFieldChangeCustomField}
                inputProps={{
                  ...params.inputProps,
                }}
                size="medium"
              />
            )}
          />
        )}
      </Stack>
      <Stack>
        <FormControlLabel
          control={
            <Checkbox
              color="success"
              size="medium"
              checked={isRequired}
              onChange={(e) => setIsRequired(e.target.checked)}
            />
          }
          label="Required"
        />
      </Stack>
    </>
  );

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">New Field</DialogTitle>
        <DialogContent sx={{ minWidth: '450px' }}>
          {renderForm}
          <Stack direction="column">
            <DialogActions
              sx={{
                mt: 2,
                p: 0,
                py: 5,
              }}
            >
              <Stack direction="row" spacing={1}>
                <LoadingButton
                  sx={{
                    color: 'white',
                    padding: '5px 20px',
                  }}
                  size="medium"
                  type="submit"
                  variant="contained"
                  color="success"
                  autoFocus
                  onClick={handleAddData}
                >
                  Add
                </LoadingButton>
                <Button size="medium" onClick={handleClose}>
                  Cancel
                </Button>
              </Stack>
            </DialogActions>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
}
