import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { get, put, post } from './http';
import { activeSnack } from '../store/common';

export const getPaymentKey = createAsyncThunk('key/payment', async ({ dispatch }) => {
  try {
    const response = await get(`/settings/public`);

    if (response) {
      return response;
    }
    return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
  } catch (error) {
    dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
    throw error?.response?.data?.message;
  }
});
export const createOtherProductPayment = createAsyncThunk(
  'createOtherProduct/payment',
  async ({ registerId, dispatch }) => {
    try {
      const response = await get(`/payment/razorpay/order/${registerId}`);

      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getallotherproductpublic = createAsyncThunk(
  'otherproduct/show',
  async ({ title, dispatch }) => {
    try {
      const URL = `/other-product/public/${title}`;

      const response = await get(URL);

      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const registerOtherProduct = createAsyncThunk(
  'otherProduct/register',
  async ({ state, reset, dispatch }) => {
    try {
      const URL = `/registration/public/new`;

      const response = await post(URL, state);

      if (response) {
        reset();
        return response;
      }
      return dispatch('something went wrong', { variant: 'error' });
    } catch (error) {
      dispatch(error?.response?.data?.message, { variant: 'error' });
      throw error?.response?.data?.message;
    }
  }
);

export const getAllOtherProducts = createAsyncThunk(
  'otherproduct/list',
  async ({ page, limit, search, filterValue, category, dispatch }) => {
    try {
      const response = await get(
        `/other-product/admin/all?page=${page ?? 1}&search=${search ?? ''}&limit=${
          limit ?? 5
        }&filterValue=${filterValue || ''}&category=${category || ''}`
      );
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllCounts = createAsyncThunk(
  'otherproduct/count',
  async ({ page, limit, search, filterValue, category, dispatch }) => {
    try {
      const response = await get(
        `/other-product/admin/count/all?page=${page ?? 1}&search=${search ?? ''}&limit=${
          limit ?? 5
        }&filterValue=${filterValue || ''}&category=${category || ''}`
      );
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createOtherProduct = createAsyncThunk(
  'otherproduct/create',
  async ({ state, dispatch, navigate }) => {
    try {
      const response = await post(`/other-product/admin/new`, state);
      if (response) {
        if (navigate) {
          navigate(paths.dashboard.other_product);
        }
        dispatch(activeSnack({ type: 'success', message: 'Created successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getOtherProductDetails = createAsyncThunk(
  'otherproduct/single',
  async ({ otherProductId, dispatch }) => {
    try {
      const response = await get(`/other-product/admin/${otherProductId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateOtherProductDetails = createAsyncThunk(
  'otherproduct/update',
  async ({ state, otherProductId, dispatch }) => {
    try {
      const URL = `/other-product/admin/${otherProductId}`;

      const response = await put(URL, state);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Updated Successfully' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
