import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useDispatch, useSelector } from 'react-redux';

import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, Grid, List, ListItem, Container, Typography, ListItemText } from '@mui/material';

import { getSettingsDetails } from 'src/server/api/settings';

import WhatsappIcon from 'src/sections/landingPage/segment/WhatsappIcon';

function MobileFooter({ company, quick }) {
  const dispatch = useDispatch();
  const { settingsDetails } = useSelector((state) => ({
    settingsDetails: state.settings.settingsDetails?.settings,
  }));
  const socialLinks = settingsDetails?.social_links;

  useEffect(() => {
    dispatch(getSettingsDetails({ dispatch }));
  }, [dispatch]);

  return (
    <div>
      <footer
        style={{
          backgroundColor: '#1A1A1A',
          padding: '20px 0',
          color: '#FFF',
          border: '1px solid #212121',
        }}
      >
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box>
                <img
                  className="footerlogo"
                  src="/assets/logo-black.png"
                  alt="Logo"
                  style={{ height: '80px', width: '100%', objectFit: 'contain', marginRight: 2 }}
                />
                <ul style={{ display: '', alignItems: 'center', justifyContent: '' }}>
                  {/* <Typography
                    sx={{
                      color: 'rgba(255, 255, 255, 0.40)',
                      px: 2,

                      textAlign: 'center',
                    }}
                  >
                    Be sure to take a look at our Terms of Use and Privacy Policy
                  </Typography> */}
                  <br />
                  <Typography sx={{ color: '#A9A9A9' }}>
                    <a
                      target="_blank"
                      href="mailto:contact@stem-xpert.com"
                      style={{ color: '#d9cbcb', textDecoration: 'none' }}
                      rel="noreferrer"
                    >
                      <span
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        contact@stem-xpert.com
                      </span>
                    </a>
                    <a style={{ color: '#d9cbcb', textDecoration: 'none' }} href="tel:9400 451 133">
                      <span
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        +91 9400 451 133
                      </span>
                    </a>
                  </Typography>
                </ul>
              </Box>
            </Grid>

            <Grid item xs={6} md={6}>
              <List dense sx={{ fontSize: 18, fontWeight: 600 }}>
                <Typography
                  sx={{
                    color: '#FFF',
                    px: 2,
                  }}
                >
                  Company
                </Typography>
                {company?.map((item, key) => (
                  <Link style={{ textDecoration: 'none' }} key={key} to={item?.link}>
                    <ListItem>
                      <ListItemText
                        className="footer-item"
                        sx={{ color: '#A9A9A9', padding: 0 }}
                        primary={item?.itemName}
                      />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Grid>

            <Grid item xs={6} md={6}>
              <List dense sx={{ fontSize: 18, fontWeight: 600 }}>
                <Typography
                  sx={{
                    color: '#FFF',
                    px: 2,
                  }}
                >
                  Quick Links
                </Typography>
                {quick?.map((item, key) => (
                  <Link style={{ textDecoration: 'none' }} key={key} to={item?.link}>
                    <ListItem sx={{}}>
                      <ListItemText
                        className="footer-item"
                        sx={{ color: '#A9A9A9' }}
                        primary={item?.itemName}
                      />
                    </ListItem>
                  </Link>
                ))}
              </List>
            </Grid>

            <Grid item xs={12} md={6} display="flex" alignItems="center" justifyContent="center">
              <Typography
                sx={{
                  marginTop: 1,
                  color: '#FFF',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                Follow Us! &nbsp;
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', color: '#A9A9A9' }}>
                {socialLinks?.x && (
                  <a
                    href={socialLinks?.x}
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#d9cbcb' }}
                    rel="noreferrer"
                  >
                    {' '}
                    <TwitterIcon sx={{ marginRight: 1 }} />
                  </a>
                )}
                {socialLinks?.facebook && (
                  <a
                    href={socialLinks?.facebook}
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#d9cbcb' }}
                    rel="noreferrer"
                  >
                    <FacebookIcon sx={{ marginRight: 1 }} />
                  </a>
                )}
                {socialLinks?.linkedIn && (
                  <a
                    href={socialLinks?.linkedIn}
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#d9cbcb' }}
                    rel="noreferrer"
                  >
                    <LinkedInIcon sx={{ marginRight: 1 }} />
                  </a>
                )}
                {socialLinks?.whatsapp && (
                  <a
                    href={`https://api.whatsapp.com/send?phone=${socialLinks?.whatsapp}`}
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#d9cbcb' }}
                    rel="noreferrer"
                  >
                    <WhatsAppIcon sx={{ marginRight: 1 }} />
                  </a>
                )}
                {socialLinks?.instagram && (
                  <a
                    href={socialLinks?.instagram}
                    target="_blank"
                    style={{ textDecoration: 'none', color: '#d9cbcb' }}
                    rel="noreferrer"
                  >
                    <InstagramIcon sx={{ marginRight: 1 }} />
                  </a>
                )}
              </Box>
            </Grid>
          </Grid>
        </Container>

        {/* Footer Copyrights */}
        <Box display="flex" alignItems="center" justifyContent="center" padding={3}>
          <Typography className="footer-copyrights">
            <span style={{ color: '#767676', fontSize: 14 }}>© 2024</span>
            <span style={{ color: '#A9A9A9', fontSize: 12 }}> www.STEM-xpert.com</span>{' '}
            <span style={{ color: '#767676', fontSize: 12 }}>
              All rights reserved.
              <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                Powered by &nbsp;
                <a href="https://osperb.com/" style={{ textDecoration: 'none', color: '#2B70F5' }}>
                  osperb
                </a>
              </span>
            </span>
          </Typography>
        </Box>
        <WhatsappIcon link={socialLinks?.whatsapp} />
      </footer>
    </div>
  );
}

export default MobileFooter;

MobileFooter.propTypes = {
  company: PropTypes.array,
  quick: PropTypes.array,
};
