import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import Link from '@mui/material/Link';
import { Alert } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

import { paths } from 'src/routes/routes/paths';
import { RouterLink } from 'src/routes/components';

import { colors } from 'src/theme/colors';
import { studentRegisterApi } from 'src/server/api/auth';

import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function AcademyRegisterView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const { error, loading } = useSelector((state) => ({
    error: state.auth.error,
    loading: state.auth.loginLoading,
  }));

  const isAcadamyExam = location?.search.substring(1);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required('Email is required'),
    phone: Yup.string()
      .required('Phone required')
      .min(10, 'Phone must be at least 10 characters')
      .max(10, 'Phone must not exceed 10 characters'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
  });

  const defaultValues = {
    name: '',
    phone: '',
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    console.log(data);
    const credentials = {
      state: data,
      enqueueSnackbar,
      navigate,
      isExam: isAcadamyExam,
    };
    dispatch(studentRegisterApi(credentials));
  });

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 3, position: 'relative' }}>
      <Typography fontSize={22} fontWeight={700}>
        Create your account in STEM-Xpert academy
      </Typography>

      <Stack direction="row" spacing={0.5}>
        <Typography variant="body2"> Already have an account? </Typography>
        <Link
          href={
            isAcadamyExam === 'isExam'
              ? `${paths.public.academy_login}?${isAcadamyExam}`
              : paths.public.academy_login
          }
          component={RouterLink}
          variant="subtitle2"
        >
          Login
        </Link>
      </Stack>
    </Stack>
  );

  const renderTerms = (
    <Typography
      component="div"
      sx={{
        mt: 2.5,
        textAlign: 'center',
        typography: 'caption',
        color: 'text.secondary',
      }}
    >
      {'By signing up, I agree to '}
      <Link underline="always" color="text.primary">
        Terms of Service
      </Link>
      {' and '}
      <Link underline="always" color="text.primary">
        Privacy Policy
      </Link>
      .
    </Typography>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
        <RHFTextField required name="name" label="Name" />
      </Stack>

      <RHFTextField required name="phone" label="Phone number" />
      <RHFTextField required name="email" label="Email address" />

      <Typography variant="caption" color={colors.secondary_1} display="flex" alignItems="center">
        <InfoRoundedIcon fontSize="small" sx={{ mx: 1 }} />
        We will send a verification mail to setup the password of your account
      </Typography>

      <LoadingButton
        fullWidth
        color="info"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting || loading}
        sx={{
          backgroundColor: colors.stem_blue,
        }}
      >
        Verify Email
      </LoadingButton>
    </Stack>
  );

  return (
    <>
      {renderHead}

      {error?.message && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error?.message}
        </Alert>
      )}

      <FormProvider methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </FormProvider>

      {renderTerms}
    </>
  );
}
