import PropTypes from 'prop-types';
import React, { useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { HTML5Backend } from 'react-dnd-html5-backend';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDrag, useDrop, DndProvider } from 'react-dnd';

import FeedIcon from '@mui/icons-material/Feed';
import InfoIcon from '@mui/icons-material/Info';
import { Add as AddIcon } from '@mui/icons-material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Box, Card, Stack, Button, Tooltip, IconButton, Typography } from '@mui/material';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';

import FormFieldCrud from './formFieldCrud';

FormDataSection.propTypes = {
  formFieldData: PropTypes.any,
  setFormFieldData: PropTypes.any,
};

export default function FormDataSection({ formFieldData, setFormFieldData }) {
  const [isFormFieldModal, setIsFormFieldModal] = useState(false);
  const [formFieldItem, setFormFieldItem] = useState({});

  const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

  const editFormField = (item) => {
    setFormFieldItem(item);
    setIsFormFieldModal(true);
  };

  const handleDelete = (index) => {
    const filteredFormFieldData = formFieldData?.filter((_, key) => key !== index);
    setFormFieldData(filteredFormFieldData);
  };

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...formFieldData];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setFormFieldData(updatedImages);
  };

  return (
    <>
      {isFormFieldModal && (
        <FormFieldCrud
          open={isFormFieldModal}
          setOpen={setIsFormFieldModal}
          formFieldData={formFieldData}
          setFormFieldData={setFormFieldData}
          formFieldItem={formFieldItem}
          setFormFieldItem={setFormFieldItem}
        />
      )}
      <DndProvider backend={HTML5Backend}>
        <Box
          sx={{
            minWidth: 600,
            marginLeft: { lg: '0', xs: 'auto' },
            marginRight: { lg: 'auto', xs: 'auto' },
          }}
        >
          <Card
            sx={{
              px: 2,
              py: 1.5,
              backgroundColor: '#F9FAFB',
              display: 'flex',
              justifyContent: 'end',
              borderRadius: '5px',
            }}
          >
            <Button
              onClick={() => setIsFormFieldModal(true)}
              size="small"
              variant="contained"
              startIcon={<AddIcon />}
              color="inherit"
            >
              New Field
            </Button>
          </Card>
          {formFieldData?.map((item, index) => (
            <FormFieldList
              item={item}
              key={item?._id}
              index={index}
              capitalizeFirstLetter={capitalizeFirstLetter}
              editFormField={editFormField}
              handleDelete={handleDelete}
              moveImage={moveImage}
            />
          ))}
          {formFieldData?.length > 0 ? (
            <Stack direction="row" spacing={1} alignItems="center">
              <InfoIcon fontSize="small" sx={{ color: '#009C85' }} />
              <Typography variant="body2" display="flex" alignItems="center" gap={0.5}>
                Form data marked with <Typography color="red">*</Typography> are required
              </Typography>
            </Stack>
          ) : (
            <Typography variant="subtitle1" pt={2} textAlign="center">
              No Fields Yet !
            </Typography>
          )}
        </Box>
      </DndProvider>
    </>
  );
}

export const FormFieldList = ({
  index,
  capitalizeFirstLetter,
  item,
  editFormField,
  handleDelete,
  moveImage,
}) => {
  const ItemType = 'IMAGE';

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index }, // Pass the current index
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });
  return (
    <Card
      ref={(node) => drag(drop(node))}
      sx={{
        px: 2,
        py: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '5px',
        my: 2,
        opacity: isDragging ? 0.5 : 1,
      }}
    >
      <Stack direction="row" spacing={4} alignItems="center">
        <Tooltip title="Hold and drag to reorder the form field">
          <DragIndicatorIcon color="Grey 300" sx={{ cursor: 'grab' }} />
        </Tooltip>
        <FeedIcon />
        <Typography variant="subtitle1" display="flex">
          {item?.custom_field?.title || item?.custom_field?.label || item?.default_field
            ? capitalizeFirstLetter(
                item?.default_field
                  ? item?.default_field
                  : item?.custom_field?.title || item?.custom_field?.label
              )
            : ''}{' '}
          {item?.is_required && <Box sx={{ color: 'red' }}>*</Box>}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={4} alignItems="center">
        <Label
          variant="soft"
          sx={{
            backgroundColor: item?.is_default_field ? '#F5F5DC' : '#DCE3F5',
            color: 'black',
            px: 2,
            py: 1.8,
            borderRadius: '4px',
          }}
        >
          {item?.is_default_field ? 'Default' : 'Custom'}
        </Label>
        <Stack direction="row" spacing={1}>
          <IconButton
            sx={{ border: '1px solid #DFE3E8', borderRadius: '5px' }}
            onClick={() => editFormField(item)}
          >
            <Iconify icon="solar:pen-bold" sx={{ color: 'black' }} />
          </IconButton>
          <IconButton
            sx={{ border: '1px solid #DFE3E8', borderRadius: '5px' }}
            onClick={() => handleDelete(index)}
          >
            <Iconify icon="solar:trash-bin-trash-bold" sx={{ color: '#B71D18 ' }} />
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
};

FormFieldList.propTypes = {
  index: PropTypes.any,
  capitalizeFirstLetter: PropTypes.any,
  item: PropTypes.any,
  editFormField: PropTypes.any,
  handleDelete: PropTypes.any,
  moveImage: PropTypes.any,
};
