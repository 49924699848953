import { createAsyncThunk } from '@reduxjs/toolkit';

import { get } from './http';
import { activeSnack } from '../store/common';

export const getAllRegistrations = createAsyncThunk(
  'registrations/list',
  async ({
    page,
    limit,
    search,
    dispatch,
    paymentType,
    otherproductId,
    partner,
    country,
    state,
    district,
    dateFrom,
    dateTo,
  }) => {
    try {
      const response = await get(
        `registration/admin/all?page=${page ?? 1}&search=${search ?? ''}&limit=${
          limit ?? 5
        }&other_product=${otherproductId || ''}&partner=${partner || ''}&payment_type=${paymentType || ''}&country=${
          country || ''
        }&state=${state || ''}&district=${district || ''}&dateFrom=${dateFrom || ''}&dateTo=${
          dateTo || ''
        }`
      );

      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getRegistrationSingleview = createAsyncThunk(
  'registration/singleview',
  async ({ Id, dispatch }) => {
    try {
      const response = await get(`registration/admin/${Id}`);
      console.log('registration datas', response);

      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const downloadRegistrations = createAsyncThunk(
  'registrations/download',
  async ({ search, dispatch, otherproductId, partner, dateFrom, dateTo }) => {
    try {
      const response = await get(
        `registration/admin/download/all?search=${search ?? ''}&other_product=${otherproductId || ''}&partner=${partner || ''}&dateFrom=${dateFrom || ''}&dateTo=${dateTo || ''}`
      );
      return response;
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
