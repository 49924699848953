import React from 'react';
import moment from 'moment';
import PropType from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Check } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
// import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Card, Grid, Stack, Button, Typography, Pagination } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { createCompletedContent, createUnCompletedContent } from 'src/server/api/schoolCourse';

import EmptyContent from 'src/components/empty-content/empty-content';

const SessionsList = ({ sessionData, schoolCourseData, page, setPage, total, schoolProfile }) => {
  const dispatch = useDispatch();
  const mdUp = useResponsive('up', 'sm');

  const handleCompletedContent = (contentId) => {
    const credentials = {
      schoolCourseId: schoolCourseData?._id,
      state: {
        content: contentId,
      },
      dispatch,
    };
    dispatch(createCompletedContent(credentials));
  };

  const handleUnCompletedContent = (id) => {
    const finalId = schoolCourseData?.completed_contents?.find((item) => item?.content === id);
    const credentials = {
      contentId: finalId?._id,
      schoolCourseId: schoolCourseData?._id,
      dispatch,
    };
    dispatch(createUnCompletedContent(credentials));
  };

  const handleCompletedStatus = (id) => {
    const isCompleted = schoolCourseData?.completed_contents?.some((item) => item?.content === id);
    // console.log(isCompleted);
    return isCompleted;
  };

  const isSubscribed = schoolProfile?.current_subscription?.payment_status === 'paid';
  const today = new Date();
  const todayDate = moment(today).format('DD-MM-yyyy');
  const endDate = moment(schoolProfile?.current_subscription?.end_date).format('DD-MM-yyyy');

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return new Date(year, month - 1, day); // month is zero-based
  };

  const handleCheckSubscription = () => {
    const date = parseDate(todayDate);
    const end = parseDate(endDate);
    return date < end;
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <Typography fontSize={18} fontWeight={700} pt="32px">
        Sessions
      </Typography>
      {sessionData?.total <= 0 && <EmptyContent filled title="No Data" sx={{ py: 10 }} />}
      <Grid container spacing={1} mt="32px">
        {sessionData?.contents?.map((item, key) => (
          <Grid key={key} md="12">
            {((isSubscribed && handleCheckSubscription()) ||
              (!isSubscribed && item?.is_free) ||
              (isSubscribed && !handleCheckSubscription() && item?.is_free)) && (
              <Card
                sx={{ mr: '24px', mb: '24px', minHeight: '92px', width: mdUp ? '75%' : '100%' }}
              >
                <Box
                  display="flex"
                  flexDirection={mdUp ? 'row' : 'column'}
                  justifyContent="space-between"
                  mx="24px"
                  mt="24px"
                >
                  <Stack>
                    <Typography
                      fontWeight={600}
                      fontSize={16}
                      display="flex"
                      flexDirection="column"
                      pb={mdUp ? 0 : 1}
                    >
                      {`${(page - 1) * 10 + key + 1}. ${item?.title}`}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Button
                      component={Link}
                      // href={`${paths?.dashboard?.session_view(item?._id)}?search=${
                      //   schoolCourseData?._id
                      // }`}
                      to={paths?.dashboard?.session_view(item?._id)}
                      state={{ schoolCourseId: schoolCourseData?._id, page }}
                      variant="outlined"
                      size="medium"
                      sx={{
                        border: '1px solid #919EAB52',
                        px: 1,
                        py: 0.5,
                        '&:hover': {
                          border: 'none',
                        },
                      }}
                    >
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        {((item?.pdf_url && item?.pdf_url?.length > 0) ||
                          (item?.training_pdf_url && item?.training_pdf_url > 0)) && (
                          <TextSnippetIcon fontSize="small" sx={{ color: '#795192' }} />
                        )}
                        {(item?.video_url || item?.video_url?.length > 0) && (
                          <PlayCircleIcon fontSize="small" sx={{ color: '#795192' }} />
                        )}
                        Go to lesson
                      </Stack>
                    </Button>
                    {handleCompletedStatus(item?._id) && (
                      <Button
                        size="medium"
                        onClick={() => handleUnCompletedContent(item?._id)}
                        sx={{
                          border: 'none',
                          px: '8px',
                          py: '4px',
                          fontWeight: 700,
                          fontSize: '13px',
                          color: '#22C55E',
                        }}
                      >
                        <Check
                          sx={{ color: '#22C55E', mr: '8px', width: '18px', height: '18px' }}
                        />
                        Completed
                      </Button>
                    )}
                    {!handleCompletedStatus(item?._id) && (
                      <Box display="flex" alignItems="center">
                        <Button
                          size="medium"
                          onClick={() => handleCompletedContent(item?._id)}
                          sx={{
                            border: '1px solid #919EAB52',
                            px: '8px',
                            py: '4px',
                            fontWeight: 600,
                            fontSize: '13px',
                            '&:hover': {
                              backgroundColor: '#00A76F29',
                              color: '#00A76F',
                              border: 'none',
                            },
                          }}
                        >
                          Mark as completed
                        </Button>
                      </Box>
                    )}
                  </Stack>
                </Box>
                <Typography fontSize={13} fontFamily={400} color="#637381" px="24px" py="12px">
                  {parse(item?.description)}
                </Typography>
              </Card>
            )}
            {((!isSubscribed && !item?.is_free) ||
              (isSubscribed && !handleCheckSubscription() && !item?.is_free)) && (
              <Card
                sx={{ mr: '24px', mb: '24px', minHeight: '92px', width: mdUp ? '75%' : '100%' }}
              >
                <Box
                  display="flex"
                  flexDirection={mdUp ? 'row' : 'column'}
                  justifyContent="space-between"
                  mx="24px"
                  mt="24px"
                >
                  <Stack>
                    <Typography
                      fontWeight={600}
                      fontSize={16}
                      display="flex"
                      flexDirection="column"
                      pb={mdUp ? 0 : 1}
                    >
                      {`${(page - 1) * 10 + key + 1}. ${item?.title}`}
                      {/* <Stack direction="row" spacing={0} mt="12px">
                        {(item?.pdf_url || item?.pdf_url?.length > 0) && (
                          <TextSnippetIcon sx={{ color: '#795192' }} />
                        )}
                        {(item?.video_url || item?.video_url?.length > 0) && (
                          <PlayCircleIcon sx={{ color: '#795192' }} />
                        )}
                      </Stack> */}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={1.5}>
                    <Button
                      // component={Link}
                      // href={`${paths?.dashboard?.session_view(item?._id)}?search=${
                      //   schoolCourseData?._id
                      // }`}
                      disabled
                      variant="outlined"
                      size="medium"
                      sx={{
                        border: '1px solid #919EAB52',
                        px: 1,
                        py: 0.5,
                        '&:hover': {
                          border: 'none',
                        },
                      }}
                    >
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        {((item?.pdf_url && item?.pdf_url?.length > 0) ||
                          (item?.training_pdf_url && item?.training_pdf_url > 0)) && (
                          <TextSnippetIcon fontSize="small" sx={{ color: 'grey' }} />
                        )}
                        {(item?.video_url || item?.video_url?.length > 0) && (
                          <PlayCircleIcon fontSize="small" sx={{ color: 'grey' }} />
                        )}
                        Go to lesson
                      </Stack>
                    </Button>
                    <Typography
                      fontWeight={700}
                      fontSize={15}
                      color="#959595"
                      // display="flex"
                      // alignItems="center"
                    >
                      <Stack direction="row" spacing={0.5}>
                        <LockIcon fontSize="small" />
                        Locked
                      </Stack>
                    </Typography>
                  </Stack>
                </Box>
                <Typography fontSize={13} fontFamily={400} color="#637381" px="24px" py="12px">
                  {parse(item?.description)}
                </Typography>
              </Card>
            )}
          </Grid>
        ))}
      </Grid>
      {sessionData?.total > 10 && (
        <Stack direction="row" justifyContent="center" mt={8}>
          <Pagination count={total} page={page} onChange={handleChange} color="standard" />
        </Stack>
        // <Pagination
        //   onChange={(e, value) => setPage(value)}
        //   count={total}
        //   defaultValue={page}
        //   sx={{
        //     mt: 8,
        //     [`& .${paginationClasses.ul}`]: {
        //       justifyContent: 'center',
        //     },
        //   }}
        // />
      )}
    </>
  );
};

export default SessionsList;

SessionsList.propTypes = {
  sessionData: PropType.any,
  schoolCourseData: PropType.any,
  page: PropType.any,
  setPage: PropType.any,
  total: PropType.any,
  schoolProfile: PropType.any,
};
