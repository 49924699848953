import { MetaHelmet } from 'src/layouts/Helmet/Helmet';

import AcademyRegisterView from 'src/sections/lms/academy-register-view';

// ----------------------------------------------------------------------

export default function AcademyRegisterPage() {
  return (
    <>
      <MetaHelmet title="Register" />

      <AcademyRegisterView />
    </>
  );
}
