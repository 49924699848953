import PropTypes from 'prop-types';

import { Stack, TextField, Autocomplete } from '@mui/material';

export default function OtherProductsTableToolbar({
  searchValue,
  onSearch,
  onFilters,
  filters,
  onCategory,
}) {
  const optionPartnerType = [
    { label: 'Other', value: 'other' },
    { label: 'Partner', value: 'partner' },
  ];

  return (
    <Stack
      spacing={2}
      direction="row"
      sx={{
        p: 2.5,
        pr: { xs: 2.5, md: 1 },
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        width: '100%',
      }}
    >
      {/* Search TextField */}
      <TextField
        sx={{
          width: { xs: '45%', sm: '45%', md: '300px' },
          height: '40px',
        }}
        size="small"
        label="Search"
        value={searchValue}
        onChange={(e) => onSearch(e.target.value)}
      />

      {/* Partner Type Autocomplete */}
      <Autocomplete
        id="partnerType-select-demo"
        sx={{
          width: { xs: '45%', sm: '45%', md: '250px' },
          height: '40px',
        }}
        options={optionPartnerType}
        autoHighlight
        size="small"
        value={optionPartnerType.find((opt) => opt.value === filters) || null}
        onChange={(event, newValue) => {
          onCategory(newValue ? newValue.value : '');
        }}
        getOptionLabel={(option) => option.label}
        renderInput={(params) => <TextField {...params} label="Category" size="small" />}
      />
    </Stack>
  );
}

OtherProductsTableToolbar.propTypes = {
  searchValue: PropTypes.string,
  onFilters: PropTypes.func,
  onSearch: PropTypes.func,
  filters: PropTypes.string,
  onCategory: PropTypes.func,
};
