import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createSchool,
  getAllSchool,
  deleteSchool,
  registerSchool,
  getSchoolDetails,
  CheckPartnerCode,
  updateSchoolDetails,
} from '../api/school';

export const schoolSlice = createSlice({
  name: 'sale',
  initialState: {
    loading: false,
    error: {},
    // sale
    school: {},
    checkPartnerCodes: {},
    staffDropList: [],
    schoolDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    [registerSchool.pending]: (state) => {
      state.loading = true;
    },
    [registerSchool.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.schoolDetails = action.payload;
    },
    [registerSchool.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    //  check partner code
    [CheckPartnerCode.pending]: (state) => {
      state.loading = true;
    },
    [CheckPartnerCode.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.checkPartnerCodes = action.payload;
    },
    [CheckPartnerCode.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // create school details
    [createSchool.pending]: (state) => {
      state.loading = true;
    },
    [createSchool.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.schools;
      const newSchoolsList = jsonState?.schools;

      const modifiedSchoolList = {
        ...jsonState,
        schools: Array.isArray(newSchoolsList)
          ? [action.payload, ...newSchoolsList]
          : [action.payload],
      };
      state.loading = false;
      state.schoolDetails = action.payload;
      state.school = modifiedSchoolList;
      state.error = {};
    },
    [createSchool.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all school
    [getAllSchool.pending]: (state) => {
      state.loading = true;
    },
    [getAllSchool.fulfilled]: (state, action) => {
      state.loading = false;
      state.school = action.payload;
      state.error = {};
    },
    [getAllSchool.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single school details
    [getSchoolDetails.pending]: (state) => {
      state.loading = true;
    },
    [getSchoolDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolDetails = action.payload;
      state.error = {};
    },
    [getSchoolDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update school details
    [updateSchoolDetails.pending]: (state) => {
      state.loading = true;
    },
    [updateSchoolDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.schoolDetails = action.payload;
      state.error = {};
    },
    [updateSchoolDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // delete school
    [deleteSchool.pending]: (state) => {
      state.loading = true;
    },
    [deleteSchool.fulfilled]: (state, action) => {
      console.log('Action Payload:', action.payload);
      const jsonState = covertToJSON(state)?.schools;
      console.log('JSON State :', jsonState);

      const modifiedSchoolList = {
        ...jsonState,
        schools: jsonState.schools?.filter((schools) => schools._id !== action.payload),
      };

      state.loading = false;
      state.school = modifiedSchoolList;
      state.error = {};
    },
    [deleteSchool.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = schoolSlice.actions;

export default schoolSlice.reducer;
