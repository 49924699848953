import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTheme } from '@emotion/react';

import { Stack } from '@mui/joy';
import InfoIcon from '@mui/icons-material/Info';
import { Tab, Tabs, alpha, Paper, Typography } from '@mui/material';

import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

SectionDetails.propTypes = {
  webImagePreview: PropTypes.any,
  setWebImagePreview: PropTypes.any,
  appImagePreview: PropTypes.any,
  setAppImagePreview: PropTypes.any,
};

export default function SectionDetails({
  webImagePreview,
  setWebImagePreview,
  appImagePreview,
  setAppImagePreview,
}) {
  const theme = useTheme();
  const [sectionTab, setSectionTab] = useState('web');

  const handleTabChange = (e, newValue) => {
    setSectionTab(newValue);
  };

  const SECTIONTABS = [
    { value: 'web', label: 'Web' },
    { value: 'mobile', label: 'Mobile' },
  ];

  return (
    <Stack mx={5}>
      <Tabs
        value={sectionTab}
        onChange={handleTabChange}
        sx={{
          boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
        }}
      >
        {SECTIONTABS.map((tab) => (
          <Tab key={tab.value} value={tab.value} label={tab.label} iconPosition="end" />
        ))}
      </Tabs>
      <Stack direction="row" spacing={1} alignItems="center" my={2}>
        <InfoIcon fontSize="small" sx={{ color: '#009C85' }} />
        <Typography variant="body2">Hold and drag images to reorder</Typography>
      </Stack>
      <Paper sx={{ backgroundColor: '#F4F6F8', p: 3 }}>
        {sectionTab === 'web' ? (
          <FileUploadCustom
            uploadUrl="stemxpert"
            setImagesPreview={setWebImagePreview}
            imagesPreview={webImagePreview}
            title="Upload Image"
            isMultiple
            isNoPreview
            type="banner"
            imageSize={4}
          />
        ) : (
          <FileUploadCustom
            uploadUrl="stemxpert"
            setImagesPreview={setAppImagePreview}
            imagesPreview={appImagePreview}
            title="Upload Image"
            isMultiple
            isNoPreview
            type="banner"
            bannerTab={sectionTab}
            imageSize={4}
          />
        )}
      </Paper>
    </Stack>
  );
}
