import { createSlice } from '@reduxjs/toolkit';

import { covertToJSON } from '../../utils/stateToJson';
import {
  createPartner,
  getAllPartner,
  deletePartner,
  registerPartner,
  getPartnerDetails,
  getPartnerProfile,
  getAllPartnerSchool,
  updatePartnerDetails,
  updatePartnerProfile,
  getPartnerSchoolDetails,
} from '../api/partner';

export const partnerSlice = createSlice({
  name: 'partner',
  initialState: {
    loading: false,
    error: {},
    // partner
    partner: {},
    staffDropList: [],
    partnerDetails: {},
    profile: {},
    partnerSchool: {},
    partnerSchoolDetails: {},
  },
  reducers: {
    clearStaffError: (state) => {
      state.error = true;
    },
  },
  extraReducers: {
    // get single partner profile
    [getPartnerProfile.pending]: (state) => {
      state.loading = true;
    },
    [getPartnerProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.profile = action.payload;
      state.error = {};
    },
    [getPartnerProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [updatePartnerProfile.pending]: (state) => {
      state.loading = true;
    },
    [updatePartnerProfile.fulfilled]: (state, action) => {
      state.loading = false;
      state.profile = action.payload;
      state.error = {};
    },
    [updatePartnerProfile.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // register partner details
    [registerPartner.pending]: (state) => {
      state.loading = true;
    },
    [registerPartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = {};
      state.partnerDetails = action.payload;
    },
    [registerPartner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // create partner details
    [createPartner.pending]: (state) => {
      state.loading = true;
    },
    [createPartner.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.partner?.partners;
      const oldPartnersList = jsonState?.partner?.partners;

      const modifiedPartnerList = {
        ...jsonState,
        partners: Array.isArray(oldPartnersList)
          ? [action.payload?.createPartnerData, ...oldPartnersList]
          : [action.payload?.createPartnerData],
      };
      state.loading = false;
      state.partnerDetails = action.payload;
      state.partner = modifiedPartnerList;
      state.error = {};
    },
    [createPartner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get all partner
    [getAllPartner.pending]: (state) => {
      state.loading = true;
    },
    [getAllPartner.fulfilled]: (state, action) => {
      state.loading = false;
      state.partner = action.payload;
      state.error = {};
    },
    [getAllPartner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    // get single partner details
    [getPartnerDetails.pending]: (state) => {
      state.loading = true;
    },
    [getPartnerDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.partnerDetails = action.payload;
      state.error = {};
    },
    [getPartnerDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // update partner details
    [updatePartnerDetails.pending]: (state) => {
      state.loading = true;
    },
    [updatePartnerDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.partnerDetails = action.payload;
      state.error = {};
    },
    [updatePartnerDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },

    [deletePartner.pending]: (state) => {
      state.loading = true;
    },
    [deletePartner.fulfilled]: (state, action) => {
      const jsonState = covertToJSON(state)?.partner;
      const modifiedPartnerList = {
        ...jsonState,
        partners: jsonState.partners?.filter((partner) => partner._id !== action.payload),
      };
      state.loading = false;
      state.partner = modifiedPartnerList;
      state.error = {};
    },
    [deletePartner.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // get all partner school
    [getAllPartnerSchool.pending]: (state) => {
      state.loading = true;
    },
    [getAllPartnerSchool.fulfilled]: (state, action) => {
      state.loading = false;
      state.partnerSchool = action.payload;
      state.error = {};
    },
    [getAllPartnerSchool.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // partner school single view
    [getPartnerSchoolDetails.pending]: (state) => {
      state.loading = true;
    },
    [getPartnerSchoolDetails.fulfilled]: (state, action) => {
      state.loading = false;
      state.partnerSchoolDetails = action.payload;
      state.error = {};
    },
    [getPartnerSchoolDetails.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
  },
});

export const { clearStaffError } = partnerSlice.actions;

export default partnerSlice.reducer;
