import React from 'react';
import PropTypes from 'prop-types';

import { Box, Grid, Stack, Button, Typography } from '@mui/material';

import { RHFEditor, RHFSwitch, RHFTextField } from 'src/components/hook-form';

BasicDetailsSection.propTypes = {
  isPaymentRequired: PropTypes.any,
  selectedCategory: PropTypes.any,
  setSelectedCategory: PropTypes.any,
};

export default function BasicDetailsSection({
  isPaymentRequired,
  selectedCategory,
  setSelectedCategory,
}) {
  return (
    <Box>
      <Stack mb={3}>
        <RHFTextField name="title" type="text" label="Title" />
      </Stack>

      <Stack mb={3}>
        <Typography variant="subtitle2" pb={1}>
          Description
        </Typography>
        <RHFEditor name="description" />
      </Stack>

      <Stack mb={3}>
        <Typography variant="subtitle2" pb={1}>
          Category
        </Typography>
        <Box display="flex" gap={4}>
          <Stack direction="row">
            <Button
              onClick={() => setSelectedCategory('PARTNER')}
              sx={{
                backgroundColor: selectedCategory === 'PARTNER' ? '#007867' : 'white',
                color: selectedCategory === 'PARTNER' ? 'white' : 'black',
                borderRadius: '8px 0 0 8px',
                border: selectedCategory === 'PARTNER' ? 'none' : '1px solid gray',
                width: '120px',
                '&:hover': {
                  backgroundColor: '#005d50',
                  color: 'white',
                },
              }}
            >
              Partner
            </Button>
            <Button
              onClick={() => setSelectedCategory('OTHER')}
              sx={{
                backgroundColor: selectedCategory === 'OTHER' ? '#007867' : 'white',
                color: selectedCategory === 'OTHER' ? 'white' : 'black',
                borderRadius: '0 8px 8px 0',
                border: selectedCategory === 'OTHER' ? 'none' : '1px solid gray',
                width: '120px',
                '&:hover': {
                  backgroundColor: '#005d50',
                  color: 'white',
                },
              }}
            >
              Other
            </Button>
          </Stack>
          <Stack direction="row" alignItems="center">
            <RHFSwitch name="is_payment_required" color="success" sx={{ mr: 0 }} />
            <Typography variant="subtitle2">Payment Required</Typography>
          </Stack>
        </Box>
      </Stack>

      {isPaymentRequired && (
        <Stack>
          <Grid item container spacing={2}>
            <Grid item md={3}>
              <RHFTextField name="price" type="number" label="Price" />
            </Grid>
            <Grid item md={3}>
              <RHFTextField name="offer_price" type="number" label="Offer Price" />
            </Grid>
            <Grid item md={3}>
              <RHFTextField
                name="partner_commission_amount"
                type="number"
                label="Partner Commission"
              />
            </Grid>
          </Grid>
        </Stack>
      )}
    </Box>
  );
}
