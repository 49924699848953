import { Link } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Container from '@mui/material/Container';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Box, Grid, List, Stack, ListItem, Typography, ListItemText } from '@mui/material';

import { getSettingsDetails } from 'src/server/api/settings';

import { useDevice } from 'src/sections/landingPage/hooks/useResponsive';
import WhatsappIcon from 'src/sections/landingPage/segment/WhatsappIcon';

import MobileFooter from './MobileFooter';

const Footer = () => {
  // const location = useLocation();
  const dispatch = useDispatch();
  const isMobiles = useDevice();
  const isMobile = isMobiles?.isMobile;

  const { settingsDetails } = useSelector((state) => ({
    settingsDetails: state.settings.settingsDetails?.settings,
  }));
  const socialLinks = settingsDetails?.social_links;

  useEffect(() => {
    dispatch(getSettingsDetails({ dispatch }));
  }, [dispatch]);

  // console.log(isMobiles);

  // console.log('data :', scrollValue);

  const footerCompanyItems = [
    {
      itemName: 'Course',
      link: '/robotics-ai-course',
    },
    {
      itemName: 'STEM Aptitude Test',
      link: '/stem-exam',
    },

    {
      itemName: 'STEM & Tinkering LAB',
      link: '/robotics-atl-tinkering-lab',
    },
    {
      itemName: 'STEM Facilitator',
      link: '/robotics-trainer ',
    },
  ];

  const footerQuickItems = [
    {
      itemName: 'About Us',
      link: '/aboutUs',
    },
    {
      itemName: 'Contact Us',
      link: '/contactUs',
    },

    {
      itemName: 'Terms & Conditions',
      link: '/terms&condition',
    },
    {
      itemName: 'Privacy & Policy',
      link: '/privacy&policy',
    },
  ];

  return (
    <>
      {isMobile ? (
        <MobileFooter company={footerCompanyItems} quick={footerQuickItems} />
      ) : (
        <>
          <footer
            style={{
              backgroundColor: '#1A1A1A',
              padding: '20px 0',
              color: '#FFF',
              border: '1px solid #212121',
            }}
          >
            <Container maxWidth="xl">
              <Grid container spacing={3}>
                {/* Company Info */}
                <Grid item xs={12} md={6}>
                  <Grid item className="">
                    <Box width={200}>
                      <img
                        className="footerlogo"
                        src="/assets/logo-black.png"
                        alt="Logo"
                        style={{
                          height: '80px',
                          width: '300px',
                          objectFit: 'contain',
                          marginLeft: -13,
                        }}
                      />
                      <ul style={{ paddingLeft: '0px' }}>
                        {/* <Typography sx={{ color: 'rgba(255, 255, 255, 0.40)' }}>
                          Be sure to take a look at
                          <br /> our Terms of Use and Privacy Policy
                        </Typography> */}
                        <br />
                        <Typography sx={{ color: '#d9cbcb' }}>
                          <a
                            style={{ color: '#d9cbcb', textDecoration: 'none' }}
                            href="tel:9400 451 133"
                          >
                            +91 9400 451 133
                          </a>
                          <br />
                          <a
                            target="_blank"
                            href="mailto:contact@stem-xpert.com"
                            style={{ color: '#d9cbcb', textDecoration: 'none' }}
                            rel="noreferrer"
                          >
                            contact@stem-xpert.com
                          </a>
                        </Typography>
                      </ul>
                    </Box>
                  </Grid>
                </Grid>

                {/* Quick Links */}
                <Grid item xs={12} md={2}>
                  <ul style={{ listStyleType: 'none', padding: 0, lineHeight: '30px', margin: 0 }}>
                    <List dense sx={{ fontSize: 18, fontWeight: 600 }}>
                      <Typography
                        sx={{
                          color: '#FFF',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingBottom: '25px',
                          marginLeft: 2,
                        }}
                      >
                        Company
                      </Typography>
                      {footerCompanyItems?.map((item, key) => (
                        <Link style={{ textDecoration: 'none' }} key={key} to={item?.link}>
                          <ListItem
                            sx={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              justifyContent: 'flex-start',
                              paddingBottom: '13px',
                            }}
                          >
                            <ListItemText
                              className="footer-item"
                              sx={{ color: '#d9cbcb' }}
                              primary={item?.itemName}
                            />
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  </ul>
                </Grid>
                <Grid item xs={12} md={2}>
                  <ul style={{ listStyleType: 'none', padding: 0, lineHeight: '30px', margin: 0 }}>
                    <List dense sx={{ fontSize: 18, fontWeight: 600 }}>
                      <Typography
                        sx={{
                          color: '#FFF',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          paddingBottom: '25px',
                          marginLeft: 2,
                        }}
                      >
                        Quick Links
                      </Typography>
                      {footerQuickItems?.map((item, key) => (
                        <Link style={{ textDecoration: 'none' }} key={key} to={item?.link}>
                          <ListItem
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              paddingBottom: '13px',
                            }}
                          >
                            <ListItemText
                              className="footer-item"
                              sx={{ color: '#d9cbcb' }}
                              primary={item?.itemName}
                            />
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  </ul>
                </Grid>

                {/* Social Links */}
                <Grid item xs={12} md={2}>
                  <Typography
                    sx={{
                      marginTop: 1,
                      color: '#FFF',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      paddingBottom: '30px',
                    }}
                  >
                    Follow Us!
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', color: '#d9cbcb' }}>
                    {/* <InstagramIcon sx={{ marginRight: 1 }} /> */}
                    {socialLinks?.whatsapp && (
                      <a
                        href={`https://api.whatsapp.com/send?phone=${socialLinks?.whatsapp}`}
                        target="_blank"
                        style={{ textDecoration: 'none', color: '#d9cbcb' }}
                        rel="noreferrer"
                      >
                        <WhatsAppIcon sx={{ marginRight: 1 }} />
                      </a>
                    )}
                    {socialLinks?.instagram && (
                      <a
                        href={socialLinks?.instagram}
                        target="_blank"
                        style={{ textDecoration: 'none', color: '#d9cbcb' }}
                        rel="noreferrer"
                      >
                        <InstagramIcon sx={{ marginRight: 1 }} />
                      </a>
                    )}
                    {socialLinks?.facebook && (
                      <a
                        href={socialLinks?.facebook}
                        target="_blank"
                        style={{ textDecoration: 'none', color: '#d9cbcb' }}
                        rel="noreferrer"
                      >
                        <FacebookIcon sx={{ marginRight: 1 }} />
                      </a>
                    )}
                    {socialLinks?.x && (
                      <a
                        href={socialLinks?.x}
                        target="_blank"
                        style={{ textDecoration: 'none', color: '#d9cbcb' }}
                        rel="noreferrer"
                      >
                        {' '}
                        <TwitterIcon sx={{ marginRight: 1 }} />
                      </a>
                    )}
                    {socialLinks?.linkedIn && (
                      <a
                        href={socialLinks?.linkedIn}
                        target="_blank"
                        style={{ textDecoration: 'none', color: '#d9cbcb' }}
                        rel="noreferrer"
                      >
                        <LinkedInIcon sx={{ marginRight: 1 }} />
                      </a>
                    )}
                    {/* <YouTubeIcon /> */}
                  </Box>
                </Grid>
              </Grid>
              <WhatsappIcon link={socialLinks?.whatsapp} isWeb />
            </Container>
          </footer>
          <Stack display="flex" alignItems="center" justifyContent="center" padding={2}>
            <Typography className="footer-copyrights">
              <span style={{ color: '#d9cbcb' }}>© 2024</span>
              <span style={{ color: '#d9cbcb' }}> www.STEM-xpert.com</span>{' '}
              <span style={{ color: '#d9cbcb' }}>All rights reserved. Powered by</span>{' '}
              <a href="https://osperb.com/" style={{ textDecoration: 'none', color: '#2B70F5' }}>
                {' '}
                osperb
              </a>
            </Typography>
          </Stack>
        </>
      )}
    </>
  );
};

export default Footer;
