import { enqueueSnackbar } from 'notistack';
// import { useNavigate } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Grid, Link, Button } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';

import { paths } from 'src/routes/routes/paths';

import { frontEndUrl } from 'src/server/api/http';
import cover from 'src/assets/Dashboard/cover.png';

import Image from 'src/components/image';
// ----------------------------------------------------------------------

export default function EarnCourse({ ...other }) {
  const theme = useTheme();
  // const navigate = useNavigate();
  const renderImg = (
    <Image
      alt="ff"
      src={cover}
      overlay={`linear-gradient(to bottom, ${alpha(theme.palette.grey[900], 0)} 0%, ${
        theme.palette.grey[900]
      } 75%)`}
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      }}
    />
  );

  // const handleCourseList = () => {
  //   sessionStorage.clear();
  //   navigate(paths.public.academy_landing);
  // };

  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };

  return (
    <Card sx={{ width: '100%', height: '100%' }}>
      <Grid sx={{ position: 'relative', height: '100%' }}>
        <Grid
          spacing={1}
          sx={{
            backgroundColor: 'transparent',
            position: 'absolute',
            top: '6.5rem',
            left: 0,
            // width: '100%',
            width: '274px',
            // height: '100%',
            zIndex: 9,
            textAlign: 'left',
            color: 'white',
            p: 2,
            marginX: 2,
          }}
        >
          <Typography
            sx={{ color: 'white', fontSize: '20px', fontWeight: '700', marginBottom: '1rem' }}
          >
            Earn with Courses!
          </Typography>

          <Typography Wrap sx={{ fontSize: '13px', fontWeight: '400', lineHeight: '22px' }}>
            Earn by sharing all our courses with a single click ! Share to start earning now.
          </Typography>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'left',
              marginTop: '10px',
            }}
          >
            <CopyToClipboard
              text={`${frontEndUrl}${paths.public.academy_landing}?ref_id=${other?.partnerCode}`}
              onCopy={handleCopy}
            >
              <Button
                // component={Link}
                // to={`${paths.public.academy_landing}`}
                variant="contained"
                color="primary"
                size="small"
                sx={{
                  color: 'white',
                  padding: '6px 10px',
                  width: '143px',
                  // marginY: '1rem',
                  fontSize: '14px',
                  '&:hover': {
                    boxShadow: 'none',
                  },
                }}
              >
                Share All Courses
              </Button>
            </CopyToClipboard>
            <Button
              component={Link}
              href={`${paths.public.academy_landing}`}
              target="_blank"
              variant="outlined"
              color="inherit"
              size="small"
              style={{
                whiteSpace: 'nowrap',
                padding: '6px 10px',
                fontSize: '14px',
                marginLeft: '5px',
              }}
            >
              View All
            </Button>
          </Stack>
          {/* </m.div> */}
        </Grid>

        {renderImg}
      </Grid>
      {/* </MotionContainer> */}
    </Card>
  );
}
