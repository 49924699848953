import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useKeyPress } from 'src/hooks/useKeyPress';
import { useResponsive } from 'src/hooks/use-responsive';

import { getSettingsDetails, updateSettingsDetails } from 'src/server/api/settings';

import FormProvider, { RHFTextField } from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function SettingsForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mdUp = useResponsive('up', 'md');

  const { settingsDetails, loading } = useSelector((state) => ({
    settingsDetails: state.settings.settingsDetails?.settings,
    loading: state.settings.loading,
  }));

  const NewJobSchema = Yup.object().shape({
    contact_details: Yup.object().shape({
      phone: Yup.string()
        .required('Phone required')
        .min(10, 'Phone must be at least 10 characters')
        .max(15, 'Phone must not exceed 15 characters'),
      email: Yup.string().email(),
      whatsapp_number: Yup.string(),
    }),
    social_links: Yup.object().shape({
      whatsapp: Yup.string(),
      instagram: Yup.string(),
      facebook: Yup.string(),
      linkedIn: Yup.string(),
      x: Yup.string(),
    }),
    defaultCurrency: Yup.string(),
    defaultCurrencySymbol: Yup.string(),
    exam_introduction: Yup.string(),
    question_limit: Yup.number(),
    instagram_token: Yup.string(),
  });

  const defaultValues = useMemo(
    () => ({
      contact_details: {
        phone: settingsDetails?.contact_details?.phone,
        email: settingsDetails?.contact_details?.email,
        whatsapp_number: settingsDetails?.contact_details?.whatsapp_number,
      },
      social_links: {
        whatsapp: settingsDetails?.social_links?.whatsapp,
        instagram: settingsDetails?.social_links?.instagram,
        facebook: settingsDetails?.social_links?.facebook,
        linkedIn: settingsDetails?.social_links?.linkedIn,
        x: settingsDetails?.social_links?.x,
      },
      defaultCurrency: settingsDetails?.defaultCurrency,
      defaultCurrencySymbol: settingsDetails?.defaultCurrencySymbol,
      exam_introduction: settingsDetails?.exam_introduction,
      question_limit: settingsDetails?.question_limit,
      instagram_token: settingsDetails?.instagram_token,
    }),
    [settingsDetails]
  );

  const methods = useForm({
    resolver: yupResolver(NewJobSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  useEffect(() => {
    if (settingsDetails) {
      reset(defaultValues);
    }
  }, [settingsDetails, defaultValues, reset]);

  useEffect(() => {
    const credentials = {
      dispatch,
    };
    dispatch(getSettingsDetails(credentials));
  }, [dispatch]);

  const onSubmit = handleSubmit(async (data) => {
    const settingsId = settingsDetails?._id;
    // const modifiedData = {
    //   ...data,
    //   address: {
    //     ...data.address,
    //     district: data?.address?.district?.value,
    //     state: data?.address?.state?.value,
    //   },
    //   phone: data?.phone?.toString(),
    //   country: data?.country?.value,
    // };

    const credentials = {
      state: data,
      settingsId,
      dispatch,
      navigate,
    };
    dispatch(updateSettingsDetails(credentials));
  });

  const [myCombination, setMyCombination] = useState('');

  const onKeyPress = (event) => {
    setMyCombination(myCombination + event.key);
  };

  useKeyPress(['Control', 'Shift', 'o', 's', 'p', 'e', 'r', 'b'], onKeyPress);

  useEffect(() => {
    if (myCombination === 'ControlShiftosperb') {
      setMyCombination('');
    }
  }, [myCombination]);

  useEffect(() => {
    if (myCombination === 'ControlShift') {
      setMyCombination('');
    }
  }, [myCombination]);

  const contactDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Contact Details
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Modify the Contact details in this section
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="contact_details.phone"
                required
                label="Phone"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="contact_details.email"
                required
                label="Email"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="contact_details.whatsapp_number"
                label="Whatsapp Number"
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const socialDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Social Links
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Modify the Social Links if necessary
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="social_links.whatsapp"
                label="Whatsapp"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="social_links.instagram"
                label="Instagram"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="social_links.facebook"
                label="Facebook"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="social_links.linkedIn"
                label="Linkdin"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="social_links.x"
                label="Twitter"
              />
            </Stack>
            {myCombination === 'osperb' && (
              <Stack spacing={1.5}>
                <RHFTextField
                  InputLabelProps={{ shrink: true }}
                  name="instagram_token"
                  label="Instagram Token"
                />
              </Stack>
            )}
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const currencyDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            Currency
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Currency Details
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Details" />}

          <Stack direction="row" spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="defaultCurrency"
                label="Default Currency"
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFTextField
                InputLabelProps={{ shrink: true }}
                name="defaultCurrencySymbol"
                label="Currency Symbol"
              />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  // const examDetails = (
  //   <>
  //     {mdUp && (
  //       <Grid md={4}>
  //         <Typography variant="h6" sx={{ mb: 0.5 }}>
  //           Exam
  //         </Typography>
  //         <Typography variant="body2" sx={{ color: 'text.secondary' }}>
  //           Exam Details
  //         </Typography>
  //       </Grid>
  //     )}

  //     <Grid xs={12} md={8}>
  //       <Card>
  //         {!mdUp && <CardHeader title="Details" />}

  //         <Stack direction="column" spacing={3} sx={{ p: 3 }}>
  //           <Stack spacing={1.5} width="100%">
  //             <RHFTextField
  //               InputLabelProps={{ shrink: true }}
  //               name="exam_introduction"
  //               label="Exam Introduction"
  //               multiline={4}
  //             />
  //           </Stack>
  //           <Stack spacing={1.5}>
  //             <RHFTextField
  //               InputLabelProps={{ shrink: true }}
  //               name="question_limit"
  //               label="Questions per page"
  //             />
  //           </Stack>
  //         </Stack>
  //       </Card>
  //     </Grid>
  //   </>
  // );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack sx={{ width: '100%' }} />
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting || loading}
          sx={{ ml: 2, width: '250px' }}
        >
          Save Changes
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {contactDetails}

        {socialDetails}

        {currencyDetails}

        {/* {examDetails} */}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
