import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';

import { Box, Grid, Stack, Button } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { frontEndUrl } from 'src/server/api/http';
import { SeoIllustration } from 'src/assets/illustrations';

import SaleItemListView from 'src/sections/saleItem/saleItemListView';
import EarnCourse from 'src/sections/common/ShareLinkAllCourse/earn-course';
import EarnReward from 'src/sections/common/ShareLinkAllCourse/earn-reward';
import AllCourseShare from 'src/sections/common/ShareLinkAllCourse/all-course-share';

export default function ShareLinkAllCourse() {
  const { partnerDetails } = useSelector((state) => ({
    loading: state.Course.loading,
    courseList: state.Course.courses,
    partnerDetails: state.partner.profile,
  }));
  const mdUp = useResponsive('up', 'md');
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  // const [sort, setSort] = useState('school');

  const handleCopy = () => {
    enqueueSnackbar('Link copied!', { variant: 'success' });
  };
  // const SPACING = 3;
  // console.log(location);
  return (
    <>
      <Stack
        sx={{
          flexDirection: 'row',
          gap: '1rem',
          '@media (max-width: 600px)': {
            flexDirection: 'column',
          },
        }}
      >
        <Box sx={{ width: mdUp ? '63%' : '100%' }}>
          <EarnReward
            title={`Earn Rewards for School Registrations!  `}
            description="Register a school directly or share your unique URL to earn rewards for each school that signs up through your link."
            img={<SeoIllustration />}
            action={
              <Stack sx={{ flexDirection: 'row', justifyContent: 'space-around' }}>
                <Grid sx={{ padding: '0' }}>
                  <Button
                    component={Link}
                    to={`${frontEndUrl}${paths.public.school_regstration}?ref_id=${partnerDetails?.findPartner?.partner_code}`}
                    target="_blank"
                    variant="contained"
                    color="primary"
                    sx={{ fontSize: '14px', paddingX: '10px', color: 'white' }}
                  >
                    Register School
                  </Button>
                </Grid>
                <Grid sx={{ padding: '0', marginLeft: '6px' }}>
                  <CopyToClipboard
                    text={`${frontEndUrl}${paths.public.school_regstration}?ref_id=${partnerDetails?.findPartner?.partner_code}`}
                    onCopy={handleCopy}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        fontSize: '14px',
                        paddingX: '10px',
                        backgroundColor: '#00A76F',
                        color: 'white',
                      }}
                    >
                      Copy Url
                    </Button>
                  </CopyToClipboard>
                </Grid>
              </Stack>
            }
          />
        </Box>
        <Box sx={{ width: mdUp ? '37%' : '100%' }}>
          <EarnCourse partnerCode={partnerDetails?.findPartner?.partner_code} />
        </Box>
      </Stack>

      <Stack lg={12} sx={{ marginTop: '1rem' }}>
        {location?.pathname === `${paths.dashboard.root}/app` ? (
          <AllCourseShare />
        ) : (
          <SaleItemListView partnerCode={partnerDetails?.findPartner?.partner_code} />
        )}
      </Stack>
    </>
  );
}
