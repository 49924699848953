import { createAsyncThunk } from '@reduxjs/toolkit';

import { paths } from 'src/routes/routes/paths';

import { useSetEncryptLocalData } from 'src/hooks/useHandleSessions';

import { del, get, put, post } from './http';
import { activeSnack } from '../store/common';

export const createSchoolCourse = createAsyncThunk(
  'school-course/create',
  async ({ dispatch, navigate }) => {
    try {
      const URL = `/school-course/admin/new`;
      const response = await post(URL);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'OnBoarding Completed successfully!!' }));
        const getSchoolUserProfile = await get('/school-user');
        useSetEncryptLocalData('isOnBoarded', getSchoolUserProfile?.is_onboarded);
        // if (getSchoolUserProfile?.is_onboarded) {
        // dispatch(getSchoolUserProfile({ dispatch }));
        navigate(paths.dashboard.root);
        return response;
        // }
      }
      return '';
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllSchoolCourse = createAsyncThunk(
  'school-course/list',
  async ({ page, search, limit, schoolClass, schoolClassDivision, sort, dispatch }) => {
    try {
      const URL = `/school-course/admin/all?page=${page || 1}&search=${search || ''}&limit=${
        limit || 10
      }&school_class=${schoolClass || ''}&school_division=${schoolClassDivision || ''}&sort=${
        sort || 'all'
      }`;

      const URL_DROPDOWN = `/school-course/admin/all?search=${search && search}`;

      const response = await get(page ? URL : URL_DROPDOWN);
      // console.log(response);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getSchoolCourseDetails = createAsyncThunk(
  'school-course/single',
  async ({ schoolCourseId, dispatch }) => {
    try {
      const response = await get(`/school-course/admin/${schoolCourseId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updateSchoolCourseDetails = createAsyncThunk(
  'school-course/update',
  async ({ state, schoolCourseId, dispatch, navigate, handleClose }) => {
    try {
      const URL = `/school-course/admin/${schoolCourseId}`;

      const response = await put(URL, state);
      if (response) {
        handleClose();
        if (navigate) {
          navigate();
        }
        dispatch(
          activeSnack({ type: 'success', message: 'School course Item updated Successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deleteSchoolCourse = createAsyncThunk(
  'school-course/delete',
  async ({ schoolCourseId, dispatch, handleCloseDeleteDialog }) => {
    try {
      const response = await del(`/school-course/admin/${schoolCourseId}/`);
      if (response) {
        handleCloseDeleteDialog();
        dispatch(
          activeSnack({ type: 'success', message: 'School course Item deleted Successfully' })
        );
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createCompletedContent = createAsyncThunk(
  'school-course/create',
  async ({ schoolCourseId, state, dispatch }) => {
    try {
      const URL = `school-course/school-user/${schoolCourseId}`;
      const response = await post(URL, state);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Completion status updated!' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const createUnCompletedContent = createAsyncThunk(
  'school-course/create',
  async ({ contentId, schoolCourseId, dispatch }) => {
    try {
      const URL = `school-course/admin/${contentId}/${schoolCourseId}`;
      const response = await post(URL);
      if (response) {
        dispatch(activeSnack({ type: 'success', message: 'Completion status updated!' }));
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
