import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import { Chip, Button, CardMedia, Typography, CardContent } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { fNumber } from 'src/utils/format-number';

import { getAllSchoolClass } from 'src/server/api/schoolClass';
import { getAllSchoolDivision } from 'src/server/api/schoolDivision';

import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import EmptyContent from 'src/components/empty-content';

import labModel1 from '../../assets/labModel/img1.png';
import LabModelViewDialog from '../app/school/labModelView';

// ----------------------------------------------------------------------

export default function ProfileDetails({ formData }) {
  const dispatch = useDispatch();

  const { data, schoolId, classCount, divisionCount, userRole } = formData;

  const address = data?.address;

  const { schoolClassList, schoolDivision } = useSelector((state) => ({
    schoolClassList: state.schoolClass.schoolClasses,
    schoolDivision: state.schoolDivision.schoolDivisions,
  }));

  const [labModelViewModal, setLabModelViewModal] = useState(false);
  const [labModelDetails, setLabModelDetails] = useState({});

  const handleLabmodelData = (item) => {
    setLabModelViewModal(true);
    setLabModelDetails(item);
  };

  const contactDetails = [
    {
      name: data?.phone,
      icon: 'eva:phone-fill',
    },
    {
      name: data?.email,
      icon: 'eva:email-fill',
    },
  ];

  const addressDetails = [
    address?.address_line1,
    address?.address_line2,
    address?.district?.name,
    address?.state?.name,
    address?.pincode,
  ];

  const renderFollows = (
    <Card sx={{ py: 3, textAlign: 'center', typography: 'h4' }}>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
      >
        <Stack width={1}>
          {fNumber(classCount ?? schoolClassList.total)}
          <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
            Classes
          </Box>
        </Stack>

        <Stack width={1}>
          {fNumber(divisionCount ?? schoolDivision?.total)}
          <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
            Divisions
          </Box>
        </Stack>
      </Stack>
    </Card>
  );

  const labModel = (
    <Card sx={{ width: '100%', marginRight: '24px', minHeight: '306px', cursor: 'pointer' }}>
      <CardMedia
        component="img"
        height="160px"
        image={
          data?.lab_model && data?.lab_model?.images?.length > 0
            ? data?.lab_model?.images[0]?.url
            : labModel1
        }
        alt="green iguana"
      />
      <CardContent>
        <Typography fontSize={15} fontWeight={600} component="div">
          {data?.lab_model?.title}
        </Typography>
        <TextMaxLine
          fontSize={13}
          fontWeight={400}
          pt={2}
          color="text.secondary"
          line={3}
          className="bg-color-remove"
        >
          {/* {data?.lab_model?.description?.slice(0, 200)}{' '}
            {data?.lab_model?.description?.length > 200 && '.....'} */}
          {data?.lab_model?.description ? parse(data?.lab_model?.description) : null}
        </TextMaxLine>
      </CardContent>
      <Stack direction="row" justifyContent="end" m={2}>
        <Button
          onClick={() => userRole === 'super_admin' && handleLabmodelData(data?.lab_model)}
          component={userRole === 'school_user' && Link}
          to={paths?.dashboard?.lab}
        >
          View More
        </Button>
      </Stack>
    </Card>
  );

  const renderContact = (
    <Card sx={{ minHeight: '164px' }}>
      <CardHeader title="Contact Details" />
      <Stack spacing={2} sx={{ p: 3 }}>
        {contactDetails?.map((item, key) => (
          <Stack
            key={key}
            spacing={2}
            direction="row"
            sx={{ wordBreak: 'break-all', typography: 'body2' }}
          >
            <Iconify
              icon={item?.icon}
              width={20}
              sx={{
                flexShrink: 0,
                color: '#1877F2',
              }}
            />
            <Typography fontWeight={400} fontSize={14}>
              {item?.name}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Card>
  );

  // const renderSocials = (
  //   <Card>
  //     <CardHeader title="Social Links" />

  //     <Stack spacing={2} sx={{ p: 3 }}>
  //       {_socials.map((link) => (
  //         <Stack
  //           key={link.name}
  //           spacing={2}
  //           direction="row"
  //           sx={{ wordBreak: 'break-all', typography: 'body2' }}
  //         >
  //           <Iconify
  //             icon={link.icon}
  //             width={24}
  //             sx={{
  //               flexShrink: 0,
  //               color: link.color,
  //             }}
  //           />
  //           <Link color="inherit">
  //             {link.value === 'facebook' && info.socialLinks.facebook}
  //             {link.value === 'instagram' && info.socialLinks.instagram}
  //             {link.value === 'linkedin' && info.socialLinks.linkedin}
  //             {link.value === 'twitter' && info.socialLinks.twitter}
  //           </Link>
  //         </Stack>
  //       ))}
  //     </Stack>
  //   </Card>
  // );

  const renderAddress = (
    <Card sx={{ minHeight: '302px' }}>
      <CardHeader title="School Address" />
      <Stack spacing={2} sx={{ p: 3 }}>
        {addressDetails?.map((item, key) => (
          <Stack
            key={key}
            spacing={2}
            direction="row"
            sx={{ wordBreak: 'break-all', typography: 'body2' }}
          >
            <Typography fontWeight={400} fontSize={14}>
              {item}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Card>
  );

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      dispatch,
      limit: 20,
      school: schoolId,
    };
    dispatch(getAllSchoolClass(credentials));
  }, [dispatch, schoolId]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: '',
      schoolClass: '',
      dispatch,
      data: 'full',
      school: schoolId,
    };
    dispatch(getAllSchoolDivision(credentials));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleDivisions = (classId) => {
    const filteredDivisions = schoolDivision?.schoolDivisions?.filter(
      (item) => item?.school_class?._id === classId
    );
    console.log('filteredDivisions', filteredDivisions);
    return filteredDivisions;
  };

  // console.log(() => handleDivisions('65ce166cd0781833ae442b48'));
  console.log('schoolDivision', schoolDivision);
  console.log(schoolClassList);

  return (
    <>
      {labModelViewModal && (
        <LabModelViewDialog
          setOpen={setLabModelViewModal}
          open={labModelViewModal}
          data={labModelDetails}
        />
      )}
      <Grid container spacing={3}>
        <Grid xs={12} md={4}>
          <Stack spacing={3}>
            {renderContact}

            {renderFollows}
          </Stack>
        </Grid>
        <Grid xs={12} md={4}>
          <Stack spacing={3}>{labModel}</Stack>
        </Grid>
        <Grid xs={12} md={4}>
          <Stack spacing={3}>{renderAddress}</Stack>
        </Grid>
      </Grid>
      <Card
        variant="outlined"
        sx={{
          marginRight: '24px',
          marginTop: '40px',
          boxShadow: 'none',
          padding: '28px',
        }}
      >
        <Typography fontSize={18} fontWeight={700}>
          Class and divisions
        </Typography>
        <Stack mt="20px">
          {schoolClassList?.schoolClasses?.map((item) => (
            <Stack direction="row" spacing={1} alignItems="center" mb="40px">
              <Chip
                label={item?.title}
                sx={{
                  width: 'max-content',
                  height: '40px',
                  borderRadius: '50px',
                  color: 'white',
                  backgroundColor: '#007867',
                  fontSize: '14px',
                  fontWeight: 700,
                  '&:hover': {
                    backgroundColor: '#007867',
                  },
                }}
              />
              <Typography fontSize="14px" fontWeight={700} pl="12px">
                :
              </Typography>
              {handleDivisions(item?._id)?.map((div) => (
                <Chip
                  label={div?.title}
                  className="div-chip"
                  sx={{
                    width: 'max-content',
                    height: '40px',
                    borderRadius: '50px',
                    color: '#212B36',
                    backgroundColor: 'white',
                    fontSize: '14px',
                    fontWeight: 700,
                    border: '1px solid #919EAB52',
                    marginLeft: '12px',
                    '&:hover': {
                      backgroundColor: 'white',
                    },
                  }}
                />
              ))}
            </Stack>
          ))}
        </Stack>
        {schoolClassList?.total <= 0 && <EmptyContent filled title="No Data" sx={{ py: 10 }} />}
      </Card>
    </>
  );
}

ProfileDetails.propTypes = {
  formData: PropTypes.any,
};
