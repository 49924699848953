import moment from 'moment';
import PropType from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Check } from '@mui/icons-material';
import LockIcon from '@mui/icons-material/Lock';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {
  Box,
  Tab,
  Grid,
  Card,
  Tabs,
  Stack,
  alpha,
  Paper,
  Button,
  Container,
  Typography,
  Pagination,
  CircularProgress,
} from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { useResponsive } from 'src/hooks/use-responsive';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import { getAllContents, getContentDetails } from 'src/server/api/content';
import {
  createCompletedContent,
  getSchoolCourseDetails,
  createUnCompletedContent,
} from 'src/server/api/schoolCourse';

import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';

import TabContent from './TabContent';

export default function SessionView({ minimal }) {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const theme = useTheme();
  const settings = useSettingsContext();
  const isMobile = useResponsive('down', 'md');

  const school_course_id = location?.state?.schoolCourseId;
  const contentPage = location?.state?.page;

  const { contentDetails, contents, schoolCourseDetails, schoolUserProfile, contentLoading } =
    useSelector((state) => ({
      contentDetails: state.content.contentDetails,
      contents: state.content.contents,
      contentLoading: state.content.loading,
      schoolCourseDetails: state.schoolCourse.schoolCourseDetails,
      schoolUserProfile: state.schoolUser.schoolUserProfile?.school,
    }));

  const [pageContent, setPageContent] = useState(1);
  const totalContent = contents?.total;
  const totalClassCount = Math.ceil(totalContent / 10);

  const [activeTab, setActiveTab] = useState('');

  // useEffect(() => {
  //   if (contentDetails && !contentDetails?.is_free) {
  //     navigate(-1);
  //   }
  // }, [contentDetails, navigate]);

  useEffect(() => {
    setPageContent(contentPage);
  }, [contentPage]);

  useEffect(() => {
    const credentials = {
      contentId: params && params?.id,
      dispatch,
    };
    dispatch(getContentDetails(credentials));
  }, [dispatch, params?.id, params]);

  useEffect(() => {
    if (contentDetails && contentDetails?.course?._id) {
      const credentials = {
        page: pageContent,
        search: '',
        limit: 10,
        courseId: contentDetails?.course?._id,
        dispatch,
      };
      dispatch(getAllContents(credentials));
    }
  }, [dispatch, contentDetails, pageContent]);

  useEffect(() => {
    const credentials = {
      schoolCourseId: school_course_id,
      dispatch,
    };
    dispatch(getSchoolCourseDetails(credentials));
  }, [dispatch, school_course_id]);

  useEffect(() => {
    if (contentDetails?.video_url || contentDetails?.video_url?.length > 0) {
      setActiveTab('video');
    } else if (contentDetails?.pdf_url && contentDetails?.pdf_url?.length > 0) {
      setActiveTab('document');
    } else {
      setActiveTab('training');
    }
  }, [contentDetails?.pdf_url, contentDetails?.training_pdf_url, contentDetails?.video_url]);

  const TABS = [];

  if (contentDetails?.video_url) {
    TABS.push({ value: 'video', label: 'Video' });
  }
  if (contentDetails?.pdf_url && contentDetails?.pdf_url?.length > 0) {
    TABS.push({ value: 'document', label: 'Trainer PPT' });
  }
  if (contentDetails?.training_pdf_url && contentDetails?.training_pdf_url?.length > 0) {
    TABS.push({ value: 'training', label: 'Trainer Material' });
  }

  const handleCompletedContent = () => {
    const credentials = {
      schoolCourseId: schoolCourseDetails?._id,
      state: {
        content: contentDetails?._id,
      },
      dispatch,
    };
    dispatch(createCompletedContent(credentials));
  };

  const handleUnCompletedContent = (id) => {
    const finalId = schoolCourseDetails?.completed_contents?.find((item) => item?.content === id);
    const credentials = {
      contentId: finalId?._id,
      schoolCourseId: schoolCourseDetails?._id,
      dispatch,
    };
    dispatch(createUnCompletedContent(credentials));
  };

  const handleCompletedStatus = () => {
    const isCompleted = schoolCourseDetails?.completed_contents?.some(
      (item) => item?.content === contentDetails?._id
    );
    return isCompleted;
  };

  const isSubscribed = schoolUserProfile?.current_subscription?.payment_status === 'paid';
  const today = new Date();
  const todayDate = moment(today).format('DD-MM-yyyy');
  const endDate = moment(schoolUserProfile?.current_subscription?.end_date).format('DD-MM-yyyy');

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('-');
    return new Date(year, month - 1, day); // month is zero-based
  };

  const handleCheckSubscription = () => {
    const date = parseDate(todayDate);
    const end = parseDate(endDate);
    return date < end;
  };

  const handleChange = (event, value) => {
    setPageContent(value);
  };

  // const currentSession = contents?.contents?.find((item) => item?._id === contentDetails?._id);
  // const otherSessions = contents?.contents?.filter((item) => item?._id !== contentDetails?._id);
  // console.log('otherSessions', otherSessions);

  return (
    <>
      {/* <Container maxWidth={settings.themeStretch ? false : 'lg'}> */}
      <Container maxWidth="xl">
        <MetaHelmet title="Content" />
        {/* {!minimal && (
          <CustomBreadcrumbs
            heading="Course Details"
            links={[
              {
                name: 'Dashboard',
                href: paths.dashboard.root,
              },

              {
                name: 'Courses',
                href: paths.dashboard.course,
              },
              {
                name: 'Course View',
                href: paths.dashboard.course_view(1),
              },
              {
                name: 'Session View',
              },
            ]}
            action=""
            sx={{
              mb: { xs: 3, md: 5 },
            }}
          />
        )} */}
        <Button
          startIcon={<ArrowBackIcon />}
          component={Link}
          to={paths?.dashboard?.course_view(schoolCourseDetails?._id)}
          variant="outlined"
          type="button"
          sx={{ mb: 3, px: 2 }}
        >
          Go back to courses
        </Button>
        <Grid container flexDirection={isMobile ? 'column' : 'row'}>
          <Grid md="9" mb={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography fontSize={24} fontWeight={700}>
                {contentDetails?.title}
              </Typography>
              {handleCompletedStatus() ? (
                <Button
                  size="medium"
                  onClick={() => handleUnCompletedContent(contentDetails?._id)}
                  sx={{
                    border: 'none',
                    px: '8px',
                    py: '4px',
                    fontWeight: 700,
                    fontSize: '13px',
                    color: '#22C55E',
                  }}
                >
                  <Check sx={{ color: '#22C55E', mr: '8px', width: '18px', height: '18px' }} />
                  Completed
                </Button>
              ) : (
                <Button
                  size="small"
                  onClick={() => handleCompletedContent()}
                  sx={{
                    border: '1px solid #919EAB52',
                    p: '8px',
                    fontWeight: 500,
                    fontSize: '13px',
                    width: '180px',
                    mr: 4,
                    '&:hover': {
                      backgroundColor: '#00A76F29',
                      color: '#00A76F',
                      border: 'none',
                    },
                  }}
                >
                  Mark as completed
                </Button>
              )}
            </Box>
            {contentDetails?.description && (
              <Typography fontSize={15} fontWeight={400} color="#637381" pt="8px" width="90%">
                {parse(contentDetails?.description)}
              </Typography>
            )}
            <Stack mt="20px" mr={2}>
              <Tabs
                value={activeTab}
                onChange={(e, newValue) => setActiveTab(newValue)}
                sx={{
                  boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {TABS?.map((tab) => (
                  <Tab key={tab.value} value={tab.value} label={tab.label} />
                ))}
              </Tabs>
              <TabContent activeTab={activeTab} />
            </Stack>
          </Grid>

          <Grid md="3" px={1}>
            <Scrollbar sx={{ maxHeight: '700px' }}>
              {contentLoading ? (
                <Paper
                  sx={{
                    textAlign: 'center',
                  }}
                >
                  <CircularProgress color="warning" />
                </Paper>
              ) : (
                <>
                  {/* {((isSubscribed && handleCheckSubscription()) ||
                    (!isSubscribed && currentSession?.is_free) ||
                    (isSubscribed && !handleCheckSubscription() && currentSession?.is_free)) && (
                    <Link
                      // to={
                      //   !currentSession?.is_free
                      //     ? '#'
                      //     : paths?.dashboard?.session_view(currentSession?._id)
                      // }
                      to={paths?.dashboard?.session_view(currentSession?._id)}
                      style={{ textDecoration: 'none' }}
                    >
                      <Card
                        sx={{
                          mb: 2,
                          backgroundColor: currentSession?._id === contentDetails?._id && '#fff4dd',
                          color:
                            currentSession?._id === contentDetails?._id &&
                            settings?.themeMode === 'dark' &&
                            'black',
                        }}
                      >
                        <Box display="flex" justifyContent="space-between" mx="24px" mt="24px">
                          <Typography
                            fontWeight={600}
                            fontSize={14}
                            display="flex"
                            flexDirection="column"
                          >
                            {`${(pageContent - 1) * 10 + 1}. ${currentSession?.title}`}
                            <Stack direction="row" spacing={0} my="12px">
                              {((currentSession?.pdf_url && currentSession?.pdf_url?.length > 0) ||
                                (currentSession?.training_pdf_url &&
                                  currentSession?.training_pdf_url?.length > 0)) && (
                                <TextSnippetIcon sx={{ color: '#795192' }} />
                              )}
                              {(currentSession?.video_url ||
                                currentSession?.video_url?.length > 0) && (
                                <PlayCircleIcon sx={{ color: '#795192' }} />
                              )}
                            </Stack>
                          </Typography>
                        </Box>
                      </Card>
                    </Link>
                  )}
                  {((!isSubscribed && !currentSession?.is_free) ||
                    (isSubscribed && !handleCheckSubscription() && !currentSession?.is_free)) && (
                    <Card
                      sx={{
                        mb: 2,
                        backgroundColor: currentSession?._id === contentDetails?._id && '#fff4dd',
                        color:
                          currentSession?._id === contentDetails?._id &&
                          settings?.themeMode === 'dark' &&
                          'black',
                      }}
                    >
                      <Box display="flex" justifyContent="space-between" mx="24px" mt="24px">
                        <Typography
                          fontWeight={600}
                          fontSize={14}
                          display="flex"
                          flexDirection="column"
                        >
                          {`${(pageContent - 1) * 10 + 1}. ${currentSession?.title}`}
                          <Stack direction="row" spacing={0} my="12px">
                            {((currentSession?.pdf_url && currentSession?.pdf_url?.length > 0) ||
                              (currentSession?.training_pdf_url &&
                                currentSession?.training_pdf_url?.length > 0)) && (
                              <TextSnippetIcon sx={{ color: 'grey' }} />
                            )}
                            {(currentSession?.video_url ||
                              currentSession?.video_url?.length > 0) && (
                              <PlayCircleIcon sx={{ color: 'grey' }} />
                            )}
                          </Stack>
                        </Typography>
                        <LockIcon
                          sx={{ mr: '8px', width: '18px', height: '18px', color: '#959595' }}
                        />
                      </Box>
                    </Card>
                  )} */}
                  {contents?.contents?.map((item, key) => (
                    <>
                      {((isSubscribed && handleCheckSubscription()) ||
                        (!isSubscribed && item?.is_free) ||
                        (isSubscribed && !handleCheckSubscription() && item?.is_free)) && (
                        <Link
                          key={key}
                          // to={!item?.is_free ? '#' : paths?.dashboard?.session_view(item?._id)}
                          to={paths?.dashboard?.session_view(item?._id)}
                          state={{ schoolCourseId: item?.course?._id, page: pageContent }}
                          style={{ textDecoration: 'none' }}
                        >
                          <Card
                            sx={{
                              mb: 2,
                              backgroundColor: item?._id === contentDetails?._id && '#fff4dd',
                              color:
                                item?._id === contentDetails?._id &&
                                settings?.themeMode === 'dark' &&
                                'black',
                            }}
                          >
                            <Box display="flex" justifyContent="space-between" mx="24px" mt="24px">
                              <Typography
                                fontWeight={600}
                                fontSize={14}
                                display="flex"
                                flexDirection="column"
                              >
                                {`${(pageContent - 1) * 10 + key + 1}. ${item?.title}`}
                                <Stack direction="row" spacing={0} my="12px">
                                  {((item?.pdf_url && item?.pdf_url?.length > 0) ||
                                    (item?.training_pdf_url &&
                                      item?.training_pdf_url?.length > 0)) && (
                                    <TextSnippetIcon sx={{ color: '#795192' }} />
                                  )}
                                  {(item?.video_url || item?.video_url?.length > 0) && (
                                    <PlayCircleIcon sx={{ color: '#795192' }} />
                                  )}
                                </Stack>
                              </Typography>
                              {/* <LockIcon
                                sx={{ mr: '8px', width: '18px', height: '18px', color: '#959595' }}
                              /> */}
                            </Box>
                          </Card>
                        </Link>
                      )}
                      {((!isSubscribed && !item?.is_free) ||
                        (isSubscribed && !handleCheckSubscription() && !item?.is_free)) && (
                        <Card
                          sx={{
                            mb: 2,
                            backgroundColor: item?._id === contentDetails?._id && '#fff4dd',
                            color:
                              item?._id === contentDetails?._id &&
                              settings?.themeMode === 'dark' &&
                              'black',
                          }}
                        >
                          <Box display="flex" justifyContent="space-between" mx="24px" mt="24px">
                            <Typography
                              fontWeight={600}
                              fontSize={14}
                              display="flex"
                              flexDirection="column"
                            >
                              {`${(pageContent - 1) * 10 + key + 1}. ${item?.title}`}
                              <Stack direction="row" spacing={0} my="12px">
                                {((item?.pdf_url && item?.pdf_url?.length > 0) ||
                                  (item?.training_pdf_url &&
                                    item?.training_pdf_url?.length > 0)) && (
                                  <TextSnippetIcon sx={{ color: 'grey' }} />
                                )}
                                {(item?.video_url || item?.video_url?.length > 0) && (
                                  <PlayCircleIcon sx={{ color: 'grey' }} />
                                )}
                              </Stack>
                            </Typography>
                            <LockIcon
                              sx={{ mr: '8px', width: '18px', height: '18px', color: '#959595' }}
                            />
                          </Box>
                        </Card>
                      )}
                    </>
                  ))}
                </>
              )}
            </Scrollbar>
            {contents?.total > 10 && (
              <Stack direction="row" justifyContent="center">
                <Pagination
                  count={totalClassCount}
                  page={pageContent}
                  onChange={handleChange}
                  color="standard"
                />
                {/* <Pagination
                  onChange={(e, value) => setPageContent(value)}
                  count={totalClassCount}
                  defaultValue={pageContent}
                  sx={{
                    mt: 2,
                    [`& .${paginationClasses.ul}`]: {
                      justifyContent: 'center',
                    },
                  }}
                /> */}
              </Stack>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

SessionView.propTypes = {
  minimal: PropType.bool,
};
