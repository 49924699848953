import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import { useMemo, useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { Tab, Tabs, alpha, Stack, Container } from '@mui/material';

import { paths } from 'src/routes/routes/paths';

import { MetaHelmet } from 'src/layouts/Helmet/Helmet';
import {
  createOtherProduct,
  getOtherProductDetails,
  updateOtherProductDetails,
} from 'src/server/api/otherProduct';

import FormProvider from 'src/components/hook-form';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';

import BannersSection from 'src/sections/otherProducts/BannersSection';
import SectionDetails from 'src/sections/otherProducts/SectionsDetails';
import FormDataSection from 'src/sections/otherProducts/FormDataSection';
import BasicDetailsSection from 'src/sections/otherProducts/BasicDetailsSection';

// ----------------------------------------------------------------------

export default function CreateOtherProduct() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const params = useParams();

  const { otherProductDetails, loading } = useSelector((state) => ({
    otherProductDetails: state.otherProducts.otherProductDetails,
    loading: state.otherProducts.updateLoading,
  }));

  const [selectedCategory, setSelectedCategory] = useState('OTHER');
  const [formFieldData, setFormFieldData] = useState([]);
  const [webBannerPreview, setWebBannerPreview] = useState([]);
  const [appBannerPreview, setAppBannerPreview] = useState([]);
  const [webSectionImagePreview, setWebSectionImagePreview] = useState([]);
  const [appSectionImagePreview, setAppSectionImagePreview] = useState([]);
  const [activeTab, setActiveTab] = useState('basicDetail');

  const handleSetselectedCategory = (category) => {
    setSelectedCategory(category);
  };

  const handleTabChange = (e, newValue) => {
    setActiveTab(newValue);
  };

  const TABS = [
    { value: 'basicDetail', label: 'Basic Details' },
    { value: 'formData', label: 'Form Data' },
    { value: 'banners', label: 'Banners' },
    { value: 'sections', label: 'Sections' },
  ];

  const otherProductSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string(),
    category: Yup.string(),
    price: Yup.number(),
    offer_price: Yup.number(),
    partner_commission_amount: Yup.number(),
    is_payment_required: Yup.boolean(),
  });

  const defaultValues = useMemo(() => {
    if (otherProductDetails?._id) {
      return {
        title: otherProductDetails?.title,
        description: otherProductDetails?.description,
        price: otherProductDetails?.price,
        offer_price: otherProductDetails?.offer_price,
        partner_commission_amount: otherProductDetails?.partner_commission_amount,
        is_payment_required: otherProductDetails?.is_payment_required,
      };
    }
    return {
      title: '',
      description: '',
      price: 0,
      offer_price: 0,
      partner_commission_amount: 0,
      is_payment_required: false,
    };
  }, [otherProductDetails]);

  useEffect(() => {
    if (otherProductDetails?._id) {
      setSelectedCategory(otherProductDetails?.category);
      setFormFieldData(otherProductDetails?.form_data_fields);
      setWebBannerPreview(otherProductDetails?.web_banner_images);
      setAppBannerPreview(otherProductDetails?.app_banner_images);
      setWebSectionImagePreview(otherProductDetails?.web_section_images);
      setAppSectionImagePreview(otherProductDetails?.app_section_images);
    }
  }, [otherProductDetails]);

  const methods = useForm({
    resolver: yupResolver(otherProductSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    handleSubmit,
    // formState: {  },
  } = methods;

  const isPaymentRequired = watch('is_payment_required');

  useEffect(() => {
    if (otherProductDetails?._id) {
      reset(defaultValues);
    }
  }, [otherProductDetails, defaultValues, reset]);

  useEffect(() => {
    if (params?.id) {
      const credentials = {
        otherProductId: params?.id,
        dispatch,
      };
      dispatch(getOtherProductDetails(credentials));
    }
  }, [dispatch, params?.id]);

  const onSubmit = handleSubmit(async (data) => {
    const filteredFormData = formFieldData?.map((item) => ({
      is_default_field: item?.is_default_field,
      default_field: item?.default_field,
      custom_field: item?.custom_field?.value,
      is_required: item?.is_required,
    }));
    const credentials = {
      state: {
        ...data,
        category: selectedCategory,
        app_banner_images: appBannerPreview,
        web_banner_images: webBannerPreview,
        app_section_images: appSectionImagePreview,
        web_section_images: webSectionImagePreview,
        form_data_fields: filteredFormData,
      },
      dispatch,
    };
    // console.log("credentials", credentials);
    if (otherProductDetails?._id) {
      dispatch(
        updateOtherProductDetails({ ...credentials, otherProductId: otherProductDetails?._id })
      );
    } else {
      dispatch(createOtherProduct({ ...credentials, navigate }));
    }
  });

  return (
    <>
      <MetaHelmet title="Other Products" />
      <Container maxWidth="xl">
        <CustomBreadcrumbs
          heading="Other Products"
          links={[
            { name: 'Dashboard', href: paths.dashboard.root },
            { name: 'Other Products', href: paths.dashboard.other_product },
            { name: otherProductDetails?._id ? 'Update Other Products' : 'Create Other Products' },
          ]}
          sx={{ mb: { xs: 3, md: 5 } }}
        />
        <Stack position="relative">
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            sx={{
              boxShadow: `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} label={tab.label} iconPosition="end" />
            ))}
          </Tabs>
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack direction="row" justifyContent="end" position="absolute" top={0} right={0}>
              <LoadingButton
                loading={loading}
                type="submit"
                size="small"
                variant="contained"
                color="success"
              >
                {otherProductDetails?._id ? 'Save' : 'Create'}
              </LoadingButton>
            </Stack>
            <Stack my={3}>
              {activeTab === 'basicDetail' && (
                <BasicDetailsSection
                  isPaymentRequired={isPaymentRequired}
                  selectedCategory={selectedCategory}
                  setSelectedCategory={handleSetselectedCategory}
                />
              )}
              {activeTab === 'formData' && (
                <FormDataSection
                  formFieldData={formFieldData}
                  setFormFieldData={setFormFieldData}
                />
              )}
              {activeTab === 'banners' && (
                <BannersSection
                  webImagePreview={webBannerPreview}
                  setWebImagePreview={setWebBannerPreview}
                  appImagePreview={appBannerPreview}
                  setAppImagePreview={setAppBannerPreview}
                />
              )}
              {activeTab === 'sections' && (
                <SectionDetails
                  webImagePreview={webSectionImagePreview}
                  setWebImagePreview={setWebSectionImagePreview}
                  appImagePreview={appSectionImagePreview}
                  setAppImagePreview={setAppSectionImagePreview}
                />
              )}
              <Stack direction="row" justifyContent="end">
                <LoadingButton
                  loading={loading}
                  sx={{ marginTop: '13px' }}
                  type="submit"
                  size="small"
                  variant="contained"
                  color="success"
                >
                  {otherProductDetails?._id ? 'Save' : 'Create'}
                </LoadingButton>
              </Stack>
            </Stack>
          </FormProvider>
        </Stack>
      </Container>
    </>
  );
}
