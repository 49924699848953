import * as Yup from 'yup';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';

import { useGetRoles } from 'src/hooks/useHandleSessions';

import { updatePartnerProfile } from 'src/server/api/partner';
// import { updateUserProfile } from 'src/server/api/auth';
import { updateSchoolUserProfile } from 'src/server/api/schoolUser';

// import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
// import { FileUploadCustom } from 'src/components/CustomFields/ImageUpload';

// ----------------------------------------------------------------------

export default function AccountGeneral() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { role } = useGetRoles();
  const { loading, schoolUserProfile, partnerProfile } = useSelector((state) => ({
    // account: state.auth.user,
    partnerProfile: state.partner.profile?.findPartner,
    loading: state.auth.loading,
    schoolUserProfile: state.schoolUser.schoolUserProfile,
  }));

  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().required('Email is required').email('Email must be a valid email address'),
    phone: Yup.string()
      .required('Phone required')
      .min(10, 'Phone must be at least 10 characters')
      .max(10, 'Phone must not exceed 10 characters'),
  });

  const defaultValues = {
    name: schoolUserProfile?.name || partnerProfile?.name || '',
    email: schoolUserProfile?.email || partnerProfile?.email || '',
    phone: schoolUserProfile?.phone || partnerProfile?.phone || '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    // setValue,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const credentials = {
      state: {
        ...data,
        // image: flagImagePreview,
      },
      dispatch,
      enqueueSnackbar,
    };
    // dispatch(updateUserProfile(credentials));
    if (role === 'partner') {
      dispatch(updatePartnerProfile(credentials));
    } else {
      dispatch(updateSchoolUserProfile(credentials));
    }
  });

  // const handleDrop = useCallback(
  //   (acceptedFiles) => {
  //     const file = acceptedFiles[0];

  //     const newFile = Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //     });

  //     if (file) {
  //       setValue('image', newFile, { shouldValidate: true });
  //     }
  //   },
  //   [setValue]
  // );

  useEffect(() => {
    reset({
      name: schoolUserProfile?.name || partnerProfile?.name,
      email: schoolUserProfile?.email || partnerProfile?.email,
      phone: schoolUserProfile?.phone || partnerProfile?.phone,
    });
  }, [reset, schoolUserProfile, partnerProfile]);

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3} mb={5}>
        {/* <Grid xs={12} md={4}>
          <Card sx={{ pt: 2, pb: 2, px: 3, textAlign: 'center' }}>
            <FileUploadCustom
              uploadUrl="/stemxpert"
              setImagesPreview={setFlagImagePreview}
              imagesPreview={flagImagePreview}
              title="Upload Image"
              isMultiple
              imageSize={4}
              theme={{
                type: 'standard',
                name: 'image',
                handleDrop,
              }}
            />
          </Card>
        </Grid> */}

        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="name" label="Name" />
              <RHFTextField name="phone" label="Phone Number" />
              <RHFTextField disabled name="email" label="Email Address" />
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting || loading}>
                Save Changes
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
