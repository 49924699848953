import * as Yup from 'yup';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useMemo, useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import LoadingButton from '@mui/lab/LoadingButton';
import { DateRangeIcon } from '@mui/x-date-pickers';
import { Box, TextField, Autocomplete, InputAdornment } from '@mui/material';

import { useResponsive } from 'src/hooks/use-responsive';

import { getAllPartner } from 'src/server/api/partner';
import { getAllCountries } from 'src/server/api/country';
import { getAllLabModel } from 'src/server/api/labModel';
import { updateSchoolDetails } from 'src/server/api/school';
import { getAllState, getAllDistrict } from 'src/server/api/address';

import FormProvider from 'src/components/hook-form';

// ----------------------------------------------------------------------

export default function UpdateSchoolProfile({ schoolData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mdUp = useResponsive('up', 'md');

  const { partnersList, labModelList, countryList, stateList, districtList, loading } = useSelector(
    (state) => ({
      partnersList: state.partner.partner,
      labModelList: state.labModel.labModel,
      countryList: state.country.country,
      stateList: state.address.state,
      districtList: state.address.district,
      loading: state.school.loading,
    })
  );

  const [selectedLabModel, setSelectedLabModel] = useState();
  const [searchLabModelText, setSearchLabModelText] = useState('');
  // partner
  const [selectedPartner, setSelectedPartner] = useState();
  const [searchPartnerText, setSearchPartnerText] = useState('');

  const [selectedCountry, setSelectedCountry] = useState();
  const [searchCountryText, setSearchCountryText] = useState('');
  const UAE_COUNTRY_ID = selectedCountry?.value === '660f7c95ca0f7e05752a3820';

  const [selectedState, setSelectedState] = useState();
  const [searchStateText, setSearchStateText] = useState('');

  const [selectedDistrict, setSelectedDistrict] = useState();
  const [searchDistrictText, setSearchDistrictText] = useState('');

  const optionPartner = [
    ...(partnersList?.partners || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  const optionLabModel = [
    ...(labModelList?.labModels || []).map((results) => ({
      label: results?.title,
      value: results?._id,
    })),
  ];

  const optionCountry = [
    ...(countryList?.countries || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  const optionState = [
    ...(stateList?.states || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  const optionDistrict = [
    ...(districtList?.districts || []).map((results) => ({
      label: results?.name,
      value: results?._id,
    })),
  ];

  useEffect(() => {
    const credentials = {
      page: '',
      search: searchPartnerText,
      dispatch,
      limit: '',
      sort: '',
    };
    dispatch(getAllPartner(credentials));
  }, [searchPartnerText, dispatch]);

  useEffect(() => {
    const credentials = {
      page: '',
      limit: '',
      search: searchLabModelText,
      dispatch,
    };
    dispatch(getAllLabModel(credentials));
  }, [searchLabModelText, dispatch]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchCountryText,
      dispatch,
      limit: 10,
    };
    dispatch(getAllCountries(credentials));
  }, [dispatch, searchCountryText]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchStateText,
      limit: 10,
      dispatch,
      countryId: selectedCountry?.value,
    };
    dispatch(getAllState(credentials));
  }, [dispatch, searchStateText, selectedCountry]);

  useEffect(() => {
    const credentials = {
      page: 1,
      search: searchDistrictText,
      limit: 10,
      dispatch,
      stateId: selectedState?.value,
    };
    dispatch(getAllDistrict(credentials));
  }, [dispatch, searchDistrictText, selectedState]);

  const defaultPartner = useMemo(
    () => ({
      label: schoolData?.partner?.name,
      value: schoolData?.partner?._id,
    }),
    [schoolData]
  );

  useEffect(() => {
    setSelectedPartner(defaultPartner);
  }, [defaultPartner]);

  const defaultLabModel = useMemo(
    () => ({
      label: schoolData?.lab_model?.title,
      value: schoolData?.lab_model?._id,
    }),
    [schoolData]
  );

  useEffect(() => {
    setSelectedLabModel(defaultLabModel);
  }, [defaultLabModel]);

  const defaultCountry = useMemo(
    () => ({
      label: schoolData?.address?.country?.name,
      value: schoolData?.address?.country?._id,
    }),
    [schoolData]
  );

  useEffect(() => {
    setSelectedCountry(defaultCountry);
  }, [defaultCountry]);

  const defaultState = useMemo(
    () => ({
      label: schoolData?.address?.state?.name,
      value: schoolData?.address?.state?._id,
    }),
    [schoolData]
  );

  useEffect(() => {
    setSelectedState(defaultState);
  }, [defaultState]);

  const defaultDistrict = useMemo(
    () => ({
      label: schoolData?.address?.district?.name,
      value: schoolData?.address?.district?._id,
    }),
    [schoolData]
  );

  useEffect(() => {
    setSelectedDistrict(defaultDistrict);
  }, [defaultDistrict]);

  const NewJobSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    phone: Yup.number().required('Phone required'),
    email: Yup.string().email(),
    installation_date: Yup.date(),
    address: Yup.object().shape({
      address_line1: Yup.string(),
      address_line2: Yup.string(),
      pincode: Yup.number().required('Pincode is required'),
      state: Yup.object(),
      district: Yup.object(),
    }),
    partner: Yup.object(),
    lab_model: Yup.object(),
  });

  const formattedInstallationDate = schoolData?.installation_date
    ? moment(schoolData?.installation_date).format('YYYY-MM-DD')
    : '';

  const defaultValues = useMemo(
    () => ({
      name: schoolData?.name || '',
      installation_date: formattedInstallationDate || '',
      // email:schoolData?.email || '',
      // phone:schoolData?.phone || '',
      address: {
        address_line1: schoolData?.address?.address_line1,
        address_line2: schoolData?.address?.address_line2,
        pincode: schoolData?.address?.pincode,
        country: defaultCountry || {},
        state: defaultState || {},
        district: defaultDistrict || {},
      },
      lab_model: defaultLabModel || {},
      partner: defaultPartner || {},
    }),
    [
      defaultCountry,
      defaultDistrict,
      defaultLabModel,
      defaultPartner,
      defaultState,
      formattedInstallationDate,
      schoolData?.address?.address_line1,
      schoolData?.address?.address_line2,
      schoolData?.address?.pincode,
      schoolData?.name,
    ]
  );

  const methods = useForm({
    resolver: yupResolver(NewJobSchema),
    defaultValues,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  // useEffect(() => {
  //   if (schoolData) {
  //     reset(defaultValues);
  //   }
  // }, [partnerDetails, defaultValues, reset, setValue, selectedPartnerType]);

  const onSubmit = handleSubmit(async (data) => {
    const schoolId = schoolData?._id;
    const modifiedData = {
      ...data,
      address: {
        ...data.address,
        pincode: parseInt(data?.address?.pincode, 10),
        country: selectedCountry?.value,
        state: selectedState?.value,
        district: selectedDistrict?.value || null,
      },
      partner: selectedPartner?.value,
      lab_model: selectedLabModel?.value,
    };

    const credentials = {
      state: modifiedData,
      schoolId,
      dispatch,
      navigate,
    };
    dispatch(updateSchoolDetails(credentials));
  });
  //     <>
  //       {mdUp && (
  //         <Grid md={4}>
  //           <Typography variant="h6" sx={{ mb: 0.5 }}>
  //             Partner Details
  //           </Typography>
  //           <Typography variant="body2" sx={{ color: 'text.secondary' }}>
  //             Modify the details of the partner in this section
  //           </Typography>
  //         </Grid>
  //       )}

  //       <Grid xs={12} md={8}>
  //         <Card>
  //           {!mdUp && <CardHeader title="Details" />}

  //           <Stack spacing={3} sx={{ p: 3 }}>
  //             <Stack>
  //               <Autocomplete
  //                 id="partner-select-demo"
  //                 sx={{ width: '100%' }}
  //                 options={optionPartnerType}
  //                 autoHighlight
  //                 getOptionLabel={(option) => option.label}
  //                 isOptionEqualToValue={(option, value) => option.value === value.value}
  //                 renderOption={(props, option) => (
  //                   <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
  //                     {option?.label}
  //                   </Box>
  //                 )}
  //                 onChange={(e, values) => setSelectedPartnerType(values)}
  //                 defaultValue={
  //                   selectedPartnerType?.value
  //                     ? selectedPartnerType
  //                     : {
  //                         label: 'select partner type...',
  //                         value: '',
  //                       }
  //                 }
  //                 renderInput={(params) => (
  //                   <TextField
  //                     {...params}
  //                     label="Partner Type"
  //                     name="partner_type"
  //                     onChange={(e) => setSearchPartnerTypeText(e.target.value)}
  //                     inputProps={{
  //                       ...params.inputProps,
  //                     }}
  //                   />
  //                 )}
  //               />
  //             </Stack>
  //             <Stack spacing={1.5}>
  //               <RHFTextField name="name" required label="Name" />
  //             </Stack>
  //             <Stack spacing={1.5}>
  //               <RHFTextField name="phone" required label="Phone" />
  //             </Stack>
  //             <Stack spacing={1.5}>
  //               <RHFTextField name="qualification" label="Qualification" />
  //             </Stack>
  //           </Stack>
  //         </Card>
  //       </Grid>
  //     </>
  //   );

  //   const addressDetails = (
  //     <>
  //       {mdUp && (
  //         <Grid md={4}>
  //           <Typography variant="h6" sx={{ mb: 0.5 }}>
  //             Address
  //           </Typography>
  //           <Typography variant="body2" sx={{ color: 'text.secondary' }}>
  //             Modify the address information if necessary.
  //           </Typography>
  //         </Grid>
  //       )}

  //       <Grid xs={12} md={8}>
  //         <Card>
  //           {!mdUp && <CardHeader title="Details" />}

  //           <Stack spacing={3} sx={{ p: 3 }}>
  //             <Stack spacing={1.5}>
  //               <RHFTextField
  //                 name="address.address_line1"
  //                 placeholder="Address Line 1"
  //                 type="textarea"
  //                 required
  //                 label="Address Line 1"
  //               />
  //             </Stack>
  //             <Stack spacing={1.5}>
  //               <RHFTextField
  //                 name="address.address_line2"
  //                 type="textarea"
  //                 placeholder="Address Line 2"
  //                 label="Address Line 2"
  //               />
  //             </Stack>

  //             <Stack spacing={1.5}>
  //               <RHFTextField
  //                 required
  //                 name="address.pincode"
  //                 label="Pincode"
  //                 type="number"
  //                 placeholder="Pincode"
  //               />
  //             </Stack>

  //             {/* <Stack>
  //               <Autocomplete
  //                 id="country-select-demo"
  //                 sx={{ width: '100%' }}
  //                 options={optionCountry}
  //                 autoHighlight
  //                 onChange={(e, v) => setSelectedCountryId(v?.value)}
  //                 getOptionLabel={(option) => option.label}
  //                 defaultValue={defaultCountry}
  //                 isOptionEqualToValue={(option, value) => option.value === value.value}
  //                 renderOption={(props, option) => (
  //                   <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
  //                     {option?.label}
  //                   </Box>
  //                 )}
  //                 renderInput={(params) => (
  //                   <TextField
  //                     {...params}
  //                     label="Country"
  //                     name="country"
  //                     onChange={(e) => setSearchCountryText(e.target.value)}
  //                     inputProps={{
  //                       ...params.inputProps,
  //                     }}
  //                   />
  //                 )}
  //               />
  //             </Stack> */}

  //             <Stack>
  //               <Autocomplete
  //                 id="state-select-demo"
  //                 sx={{ width: '100%' }}
  //                 options={optionState}
  //                 autoHighlight
  //                 onChange={(e, v) => setSelectedStateId(v?.value)}
  //                 getOptionLabel={(option) => option.label}
  //                 defaultValue={defaultState}
  //                 isOptionEqualToValue={(option, value) => option.value === value.value}
  //                 renderOption={(props, option) => (
  //                   <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
  //                     {option?.label}
  //                   </Box>
  //                 )}
  //                 renderInput={(params) => (
  //                   <TextField
  //                     {...params}
  //                     label="State"
  //                     required
  //                     name="state"
  //                     onChange={(e) => setSearchValueSate(e.target.value)}
  //                     inputProps={{
  //                       ...params.inputProps,
  //                     }}
  //                   />
  //                 )}
  //               />
  //             </Stack>

  //             <Stack>
  //               <Autocomplete
  //                 id="district-select-demo"
  //                 sx={{ width: '100%' }}
  //                 options={optionDistrict}
  //                 autoHighlight
  //                 getOptionLabel={(option) => option.label}
  //                 defaultValue={defaultDistrict}
  //                 isOptionEqualToValue={(option, value) => option.value === value.value}
  //                 renderOption={(props, option) => (
  //                   <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
  //                     {option?.label}
  //                   </Box>
  //                 )}
  //                 renderInput={(params) => (
  //                   <TextField
  //                     {...params}
  //                     label="District"
  //                     required
  //                     name="district"
  //                     onChange={(e) => setSearchValueDistrict(e.target.value)}
  //                     inputProps={{
  //                       ...params.inputProps,
  //                     }}
  //                   />
  //                 )}
  //               />
  //             </Stack>
  //           </Stack>
  //         </Card>
  //       </Grid>
  //     </>
  //   );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} mt={5} sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack sx={{ width: '100%' }} />
        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={loading}
          sx={{ ml: 2, width: '250px' }}
        >
          Save Changes
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Card sx={{ padding: 4 }}>
        <Stack direction="column" spacing={2}>
          {/* <Stack sx={{ width: '100%', marginBottom: '15px' }} spacing={2}>
                {error.message && <Alert severity="error">{error?.message}</Alert>}
              </Stack> */}
          <Stack spacing={2} direction="row">
            <Autocomplete
              id="partner-select-demo"
              sx={{ width: '100%' }}
              options={optionPartner}
              autoHighlight
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option?.label}
                </Box>
              )}
              value={defaultPartner}
              defaultValue={defaultPartner}
              onChange={(e, values) => setSelectedPartner(values)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Partner"
                  // name="head_partner_type"
                  onChange={(e) => setSearchPartnerText(e.target.value)}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  size="small"
                />
              )}
            />
            <Autocomplete
              id="partner-select-demo"
              sx={{ width: '100%' }}
              options={optionLabModel}
              autoHighlight
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option?.label}
                </Box>
              )}
              value={defaultLabModel}
              defaultValue={defaultLabModel}
              onChange={(e, values) => setSelectedLabModel(values)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Lab Model"
                  // name="head_partner_type"
                  onChange={(e) => setSearchLabModelText(e.target.value)}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  size="small"
                />
              )}
            />
          </Stack>
          <Stack spacing={2} direction="row">
            <TextField
              name="name"
              label="Name"
              {...register('name', {
                required: {
                  value: true,
                  message: 'Name is required',
                },
              })}
              sx={{ width: '100%', mb: 2 }}
              error={!!errors?.name}
              helperText={errors?.name?.message}
              size="small"
              type="text"
            />
            <TextField
              name="phone"
              label="Phone "
              defaultValue={schoolData?.phone}
              disabled
              {...register('phone', {
                // required: {
                //   value: false,
                //   message: 'Phone is required',
                // },
                required: { value: false, message: 'Phone is required' },
                maxLength: { value: 15, message: 'Phone number must be 15 characters' },
                minLength: { value: 9, message: 'Phone number must be 9 characters' },
              })}
              error={!!errors.phone}
              helperText={errors?.phone?.message}
              sx={{ width: '100%', mb: 2 }}
              size="small"
              type="number"
            />
          </Stack>

          <Stack spacing={2} direction="row">
            <TextField
              name="email"
              label="Email"
              disabled
              defaultValue={schoolData?.email}
              {...register('email', {
                required: {
                  value: false,
                  message: 'Email  is required',
                },
              })}
              sx={{ width: '100%', mb: 2 }}
              error={!!errors?.email}
              helperText={errors?.email?.message}
              size="small"
              type="email"
            />
            <TextField
              name="installation_date"
              label="Installation Date"
              type="date"
              {...register('installation_date', {
                required: {
                  value: false,
                  message: 'Installation Date is required',
                },
              })}
              sx={{ width: '100%', mb: 2 }}
              error={!!errors?.installation_date}
              helperText={errors?.installation_date?.message}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DateRangeIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack spacing={2} direction="row">
            <TextField
              name="address.address_line1"
              label="Address Line1"
              {...register('address.address_line1', {
                required: {
                  value: false,
                  message: 'Address Line1 is required',
                },
              })}
              sx={{
                width: '100%',
                mb: 2,
                '& .MuiInputBase-root': {
                  minHeight: '100px', // Adjust this value as needed
                  display: 'flex',
                  alignItems: 'flex-start', // Align the text to the top
                  flexWrap: 'wrap',
                },
                '& .MuiInputBase-input': {
                  fontSize: '12px', // Remove padding
                },
              }}
              error={!!errors?.address_line1}
              helperText={errors?.address_line1?.message}
              size="small"
              type="text"
              multiline
            />
            <TextField
              name="address.address_line2"
              label="Address Line2 "
              {...register('address.address_line2', {
                required: {
                  value: false,
                  message: 'Address Line2 is required',
                },
                // required: { value: false, message: 'Phone is required' },
                // maxLength: { value: 15, message: 'Phone number must be 15 characters' },
                // minLength: { value: 9, message: 'Phone number must be 9 characters' },
              })}
              error={!!errors.address_line2}
              helperText={errors?.address_line2?.message}
              sx={{
                width: '100%',
                mb: 2,
                '& .MuiInputBase-root': {
                  minHeight: '100px', // Adjust this value as needed
                  display: 'flex',
                  alignItems: 'flex-start', // Align the text to the top
                  flexWrap: 'wrap',
                },
                '& .MuiInputBase-input': {
                  fontSize: '12px', // Remove padding
                },
              }}
              size="small"
              multiline
              type="text"
            />
          </Stack>
          <Stack spacing={2} direction="row" sx={{ marginTop: '1rem' }}>
            <TextField
              name="address.pincode"
              label="Pin-code"
              {...register('address.pincode', {
                required: {
                  value: false,
                  message: 'pin-code head is required',
                },
              })}
              sx={{ width: '100%', mb: 2 }}
              error={!!errors?.pincode}
              helperText={errors?.pincode?.message}
              size="small"
              type="number"
            />
            <Autocomplete
              id="partner-select-demo"
              sx={{ width: '100%' }}
              options={optionCountry}
              autoHighlight
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option?.label}
                </Box>
              )}
              value={defaultCountry}
              defaultValue={defaultCountry}
              onChange={(e, values) => setSelectedCountry(values)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country"
                  // name="head_partner_type"
                  onChange={(e) => setSearchCountryText(e.target.value)}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  size="small"
                />
              )}
            />
          </Stack>
          <Stack spacing={2} direction="row" sx={{ marginTop: '1rem' }}>
            <Autocomplete
              id="partner-select-demo"
              sx={{ width: '100%' }}
              options={optionState}
              autoHighlight
              getOptionLabel={(option) => option.label}
              isOptionEqualToValue={(option, value) => option.value === value.value}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  {option?.label}
                </Box>
              )}
              value={defaultDistrict}
              defaultValue={defaultDistrict}
              onChange={(e, values) => setSelectedState(values)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State/Emirates/Province"
                  // name="head_partner_type"
                  onChange={(e) => setSearchStateText(e.target.value)}
                  inputProps={{
                    ...params.inputProps,
                  }}
                  size="small"
                />
              )}
            />
            {!UAE_COUNTRY_ID && (
              <Autocomplete
                id="partner-select-demo"
                sx={{ width: '100%' }}
                options={optionDistrict}
                autoHighlight
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option?.label}
                  </Box>
                )}
                value={defaultDistrict}
                defaultValue={defaultDistrict}
                onChange={(e, values) => setSelectedDistrict(values)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="District"
                    // name="head_partner_type"
                    onChange={(e) => setSearchDistrictText(e.target.value)}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    size="small"
                  />
                )}
              />
            )}
          </Stack>
        </Stack>
        {renderActions}
      </Card>
    </FormProvider>
  );
}

UpdateSchoolProfile.propTypes = {
  schoolData: PropTypes.any,
};
