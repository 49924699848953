import { createAsyncThunk } from '@reduxjs/toolkit';

import { del, get, post } from './http';
import { activeSnack } from '../store/common';

export const createPartnerCommission = createAsyncThunk(
  'partner-commission/create',
  async ({ state, dispatch, handleClose, handleClear }) => {
    try {
      const URL = `/partner-commission/admin/new/bulk`;
      const response = await post(URL, state);
      if (response) {
        handleClose();
        handleClear();
        const cred = {
          page: 1,
          limit: sessionStorage.getItem(`rowsPerPage_partnerCommission`),
          type: 'school',
          dispatch,
        };
        dispatch(getAllPartnerCommission(cred));
        dispatch(
          activeSnack({ type: 'success', message: 'partner commission created successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getAllPartnerCommission = createAsyncThunk(
  'partner-commission/list',
  async ({ page, type, limit, partnerType, country, dispatch }) => {
    try {
      const response = await get(
        `/partner-commission/admin/all?page=${page && page}&limit=${limit || 5}&type=${
          type && type
        }&partner_type=${partnerType || ''}&country=${country || ''}`
      );
      // console.log(response);
      if (response) {
        return {
          response,
        };
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const getPartnerCommissionDetails = createAsyncThunk(
  'partner-commission/single',
  async ({ partnerCommissionId, dispatch }) => {
    try {
      // console.log('ID:', partnerCommissionId);
      const response = await get(`/partner-commission/admin/${partnerCommissionId}`);
      if (response) {
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const updatePartnerCommissionDetails = createAsyncThunk(
  'partner-commission/update',
  async ({ state, partnerCommissionId, dispatch, handleClose, handleClear }) => {
    try {
      // const URL = `/partner-commission/admin/${partnerCommissionId}`;
      const BULK_URL = `/partner-commission/admin/update/bulk`;

      const response = await post(BULK_URL, state);
      if (response) {
        handleClose();
        // if (navigate) {
        //   navigate();
        // }
        dispatch(
          getAllPartnerCommission({
            page: 1,
            search: '',
            type: 'school',
            limit: sessionStorage.getItem(`rowsPerPage_admin-partnerCommission`),
            partnerType: '',
            country: '',
            dispatch,
          })
        );
        dispatch(
          activeSnack({ type: 'success', message: 'partner-commission updated Successfully' })
        );
        return response;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);

export const deletePartnerCommission = createAsyncThunk(
  'partner-commission/delete',
  async ({ partnerCommissionId, dispatch }) => {
    try {
      const response = await del(`/partner-commission/admin/${partnerCommissionId}/`);
      if (response) {
        const cred = {
          page: 1,
          limit: sessionStorage.getItem(`rowsPerPage_partnerCommission`),
          type: 'school',
          dispatch,
        };
        dispatch(getAllPartnerCommission(cred));
        dispatch(
          activeSnack({ type: 'success', message: 'Partner Commission deleted Successfully' })
        );
        return response?._id;
      }
      return dispatch(activeSnack({ type: 'error', message: 'something went wrong' }));
    } catch (error) {
      dispatch(activeSnack({ type: 'error', message: error?.response?.data?.message }));
      throw error?.response?.data?.message;
    }
  }
);
