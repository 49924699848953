import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';

import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import { Box, Stack, Typography } from '@mui/material';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import VerifiedIcon from '@mui/icons-material/Verified';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useGetRoles } from 'src/hooks/useHandleSessions';

import { _userAbout } from 'src/_mock';
import { getSchoolDetails } from 'src/server/api/school';
import schoolImage from 'src/assets/other/schoolCover.jpeg';

import Iconify from 'src/components/iconify';

// import ProfileHome from '../profile-home';
import ProfileCover from './profileCover';
import ProfileDetails from './ProfileDetails';
import UpdateSchoolProfile from './school-profile-update';

// ----------------------------------------------------------------------

export default function SchoolProfileView() {
  const { user } = useMockedUser();
  const { role } = useGetRoles();
  const dispatch = useDispatch();
  const params = useParams();

  const { schoolUserProfile, schoolDetails } = useSelector((state) => ({
    schoolUserProfile: state.schoolUser.schoolUserProfile,
    schoolDetails: state.school.schoolDetails,
  }));

  const TABS = [
    {
      value: 'profile',
      label: 'Profile',
      icon: <Iconify icon="solar:user-id-bold" width={24} />,
    },
  ];

  if (role === 'super_admin') {
    TABS.push({
      value: 'update',
      label: 'Update School',
      icon: <Iconify icon="solar:users-group-rounded-bold" width={24} />,
    });
  }

  const handleData = () => {
    switch (role) {
      case 'super_admin':
        return schoolDetails?.findSchool;
      case 'school_user':
        return schoolUserProfile?.school;
      default:
        return '';
    }
  };

  console.log('handleData', handleData());

  useEffect(() => {
    if (role === 'super_admin') {
      const credentials = {
        schoolId: params?.id,
        dispatch,
      };
      dispatch(getSchoolDetails(credentials));
    }
  }, [dispatch, params?.id, role]);

  const formData = {
    data: handleData(),
    schoolId: params?.id,
    classCount: schoolDetails?.totalSchoolClassesCount,
    divisionCount: schoolDetails?.totalSchoolDivisionsCount,
    userRole: role,
  };

  const [currentTab, setCurrentTab] = useState('profile');

  const handleChangeTab = useCallback((event, newValue) => {
    setCurrentTab(newValue);
  }, []);

  return (
    <Stack>
      <Card
        sx={{
          mb: 3,
          height: 290,
        }}
      >
        <ProfileCover
          role={_userAbout.role}
          name={handleData()?.name}
          avatarUrl={user?.photoURL}
          coverUrl={schoolImage}
        />

        <Tabs
          value={currentTab}
          onChange={handleChangeTab}
          sx={{
            width: 1,
            bottom: 0,
            zIndex: 9,
            position: 'absolute',
            bgcolor: 'background.paper',
            alignItems: 'center',
            [`& .${tabsClasses.flexContainer}`]: {
              pr: { md: 3 },
              justifyContent: {
                sm: 'center',
                md: 'flex-end',
              },
            },
          }}
        >
          <Box sx={{ width: '100%', height: '100%', mt: 1 }}>
            {handleData()?.current_subscription &&
            handleData()?.current_subscription?.payment_status === 'paid' ? (
              <Box display="flex" alignItems="center" justifyContent="flex-start" pl="24px">
                <VerifiedIcon fontSize="large" sx={{ pr: '8px', color: '#1877F2' }} />
                <Typography fontSize={14} fontWeight={600} color="#1877F2">
                  Subscribed
                </Typography>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" justifyContent="flex-start" pl="24px">
                <InfoOutlinedIcon fontSize="large" sx={{ pr: '8px', color: '#B76E00' }} />
                <Typography fontSize={14} fontWeight={600} color="#B76E00">
                  Not Subscribed
                </Typography>
              </Box>
            )}
          </Box>
          {TABS.map((tab) => (
            <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
          ))}
        </Tabs>
      </Card>
      {currentTab === 'profile' && <ProfileDetails formData={formData} />}
      {currentTab === 'update' && (
        <UpdateSchoolProfile schoolData={schoolDetails?.findSchool} setCurrentTab={setCurrentTab} />
      )}
    </Stack>
  );
}
